import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  USER_LOGGED,
} from '../../actions/app';

const defaultState = {
  notification: {
    isOpened: false,
    title: '',
    message: '',
  },
  user: {
    username: '',
  },
};

const defaultAction = { type: '', payload: undefined };

export default (state = defaultState, action = defaultAction) => {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      return {
        ...state,
        notification: {
          title: action.payload.title,
          message: action.payload.message,
          isOpened: true,
        },
      };
    }

    case HIDE_NOTIFICATION: {
      return {
        ...state,
        notification: {
          title: '',
          message: '',
          isOpened: false,
        },
      };
    }

    case USER_LOGGED: {
      return {
        ...state,
        user: {
          username: action.payload.username,
        },
      };
    }

    default:
      return state;
  }
};
