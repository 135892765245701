import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  // Box,
  Button,
  // FormControlLabel,
  // Checkbox,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import superagent from 'superagent';
import {
  showUserName,
  showNotification,
} from '../redux/modules/app/appActions';

const propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

const styles = {
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  login: {
    maxWidth: '28.8rem',
    textAlign: 'center',
  },
  logo: {
    marginBottom: '1.92rem',
  },
  title: {
    fontSize: '2rem',
    color: '#303E4E',
    fontWeight: 'bold',
    marginBottom: '2.56rem',
  },
  checkboxWrp: {
    width: '100%',
    textAlign: 'left',
  },
  formControl: {
    marginBottom: '1rem',
  },
  // button: {
  //   marginTop: '2rem',
  // },
};

function PageLogin(props) {
  const { classes } = props;
  // const [checked, setChecked] = useState(true);
  const [user, setUser] = useState({
    name: '',
    password: '',
  });
  const dispatch = useDispatch();
  const ShowUserName = (action) => dispatch(showUserName(action));
  const ShowNotification = (action) =>
    dispatch(showNotification(action));

  const history = useHistory();

  // const handleChange = (event) => {
  //   setChecked(event.target.checked);
  // };

  const handleSignIn = async () => {
    try {
      if (user.name.trim().length === 0) {
        ShowNotification({
          title: 'Error',
          message: 'Please enter your username',
        });
      } else if (user.password.trim().length === 0) {
        ShowNotification({
          title: 'Error',
          message: 'Please enter password',
        });
      } else {
        const response = await superagent
          .post('/auth')
          .set('Content-Type', 'application/json')
          .send({
            username: user.name.trim(),
            password: user.password.trim(),
          });
        localStorage.setItem('isLoggedIn', true);
        history.push('/email');
        ShowUserName({
          username: user.name.trim(),
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleKeyboard = (e) => {
    if (
      e.keyCode === 13 &&
      e.key === 'Enter' &&
      user.name.trim().length > 0 &&
      user.password.trim().length > 0
    ) {
      handleSignIn();
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', handleKeyboard);
    return () => {
      window.removeEventListener('keypress', handleKeyboard);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleChange = (name) => (event) => {
    setUser({ ...user, [name]: event.target.value });
  };

  return (
    <div className={classes.root}>
      <div className={classes.login}>
        <Typography className={classes.title}>
          Sign in to your account
        </Typography>
        <TextField
          id="username"
          label="Username"
          variant="outlined"
          value={user.name}
          onChange={handleChange('name')}
          fullWidth
          classes={{
            root: classes.formControl,
          }}
        />
        <TextField
          id="password"
          label="Password"
          variant="outlined"
          fullWidth
          value={user.password}
          onChange={handleChange('password')}
          type="password"
          classes={{
            root: classes.formControl,
          }}
        />
        {/* <Box className={classes.checkboxWrp}>
          <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleChange} />
            }
            label="Remember me"
          />
        </Box> */}
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSignIn}
        >
          Sign in
        </Button>
      </div>
    </div>
  );
}

PageLogin.propTypes = propTypes;
export default withStyles(styles)(PageLogin);
