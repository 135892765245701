import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CircleIcon = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 48 48" {...props}>
      <g strokeWidth="4">
        <circle cx="24" cy="24" r="24" stroke="none" />
        <circle cx="24" cy="24" r="22" fill="none" />
      </g>
    </SvgIcon>
  );
};

export default CircleIcon;
