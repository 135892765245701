import React from 'react';
import { SvgIcon } from '@material-ui/core';

const SearchIcon = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 16 15.997" {...props}>
      <path
        d="M17.754,16.628l-2.966-2.944A7.194,7.194,0,1,0,13.677,14.8l2.942,2.944a.795.795,0,1,0,1.135-1.112ZM9.192,14.8a5.6,5.6,0,1,1,5.6-5.6,5.6,5.6,0,0,1-5.6,5.6Z"
        transform="translate(-1.979 -1.979)"
      />
    </SvgIcon>
  );
};

export default SearchIcon;
