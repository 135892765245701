import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compareTwoStrings } from 'string-similarity';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  withStyles,
  Typography,
  Tabs,
  Tab,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from '@mui/material';
import { CancelIcon } from '../icons';
import TabPanel from '../uikits/TabPanel';
import Link from './Link';
import Loader from '../uikits/Loader';

const propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  classes: PropTypes.instanceOf(Object).isRequired,
  parseEmail: PropTypes.shape({}),
  filesExists: PropTypes.instanceOf(Object).isRequired,
};

const defaultProps = {
  parseEmail: null,
  isOpen: false,
  handleClose: () => { },
};

const styles = (theme) => ({
  paper: {
    minWidth: '750px',
    minHeight: '51.2rem',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      minHeight: '100%',
      borderRadius: 0,
    },
  },
  header: {
    padding: '3rem 3rem 0 3rem',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('xs')]: {
      padding: '1.5rem 1.5rem 1rem 1.5rem',
    },
  },
  headerBlock: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  title: {
    width: '98%',
    fontSize: '1.5rem',
    fontWeight: 600,
    whiteSpace: 'pre-line',
    color: '#4A5568',
  },
  closeBtn: {
    backgroundColor: 'transparent',
    color: '#A0AEC0',
    '& svg': {
      color: '#A0AEC0',
      fontSize: '1rem',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  tabs: {
    minHeight: '2rem',
    marginTop: '1.5rem',
    paddingBottom: '1.5rem',
    borderBottom: '0.16rem solid #EDF2F7',
  },
  flexContainer: {
    width: '49.5rem',
    borderRadius: '0.75rem',
    backgroundColor: '#EDF2F7',
    padding: '0.25rem',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  indicator: {
    display: 'none',
  },
  tabWrapper: {
    minHeight: '1.75rem',
    color: '#111111',
    borderRadius: '0.75rem',
  },
  selected: {
    backgroundColor: '#FFFFFF',
  },
  tab: {
    fontSize: '0.875rem',
  },
  rootContent: {
    paddingTop: 0,
  },
  loaderWrp: {
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '100%',
    maxHeight: '25%',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '0 auto',
    zIndex: 999,
    opacity: 0.5,
    backgroundColor: '#FFFFFF',
    bottom: 0,
  },
  wrp: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  coloredWrp: {
    display: 'flex',
    minHeight: '30rem',
    overflowX: 'auto',
    backgroundColor: '#FAFBFC',
    border: '0.08rem solid #EDF2F7',
    borderRadius: '0.75rem',
    padding: '1.6rem',
    '& $right': {
      textAlign: 'start',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '36rem',
    },
  },
  linksWrp: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '1.2rem',
    marginTop: 0,
    marginBottom: 0,
  },
  linkItem: {
    fontSize: '1rem',
    color: '#748297',
  },
  linksBlock: {
    display: 'flex',
  },
  left: {
        whiteSpace: 'nowrap',
  },
  right: {
    textAlign: 'start',
        whiteSpace: 'nowrap',

    flexGrow: 1,
    // marginBottom: '3.75%',
    marginBottom: '8.9rem',
     minHeight: '20.75px',
    //marginBottom: '28%',
  },
  text: {
    border: '1px solid silver',
    minHeight: '20.75px',
    color: '#748297',
    '&:nth-child(even)': {
          backgroundColor: '#D3D3D3',
    },
    // '@media (max-width: 599px)': {
    //   height: '40px',
    // }
    
  },
  jsonText: {
    fontSize: '1rem',
    color: '#4A5568',
    whiteSpace: 'pre-wrap',
    margin: 0,
  },
  textPrimary: {
    border: '1px solid silver',
    minHeight: '20.75px',
    color: '#4A5568',
    flexGrow: 1,
    '&:nth-child(even)': {
          backgroundColor: '#D3D3D3',
    },
    // '@media (max-width: 599px)': {
    //   height: '40px',
    // }
  },
  searchBtn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 400,
    lineHeight: 0,
  },
  filterBlock: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  fourButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  reparseEmailLoadingButton: {
    padding: 0,
    minWidth: '28px !important',
    maxWidth: '28px'
  },
});

const EmailParserModal = (props) => {
  const {
    isOpen,
    handleClose,
    classes,
    parseEmail,
    handleParseAttachments,
    handleParseRelevantLinks,
    handleParseAll,
    handleMailRefresh,
    handleMailReparse,
    handleSaveToDatabase,
    handleAddToDatabase,
    filesExists,
    isLoading
  } = props;
  
  
  const relevantRegExp = new RegExp('https?:\\/\\/\\S+', 'gm')

  console.log(relevantlinks, 'relevantlinks count');
  console.log(mainReg, 'mainreg');
  
  const {
    unique_id = '',
    uid = '',
    filename= '',
    path = '',
    udate = '',
    mailhouse_id = 0,
    mailhouse = '',
    all_links_count = 0,
    attachmentsfound = 0,
    pdfs_array = [],
    relevantlinks = 0,
    emailfrom = '',
    from_key = '',
    email_subject = '',
    all_links = [],
    totallinkstext = [],
    folder_name,
    emailId,
    linksfound = 0,
    housefound = 0,
    housename = '',
    senderfound = 0,
    totallinks = 0,
    header = '',
    subject = '',
    html = '',
    mail2pdfFlagForJS = false,
    all_raw_links = [],
    regex_raw_links = [],
    relevant_raw_links = [],
    attachments = [],
    all_attachment_count = 0,
    attachment_count = 0,
    is_desk_commentary = false,
    links_downloaded = false,
    sent_to_java = false,
    should_ignore = false,
    message_uid = 0,
    date = '',
    message_id = 0,
    relevant_links_original = [],
  } = parseEmail || {};
  const [index, setIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setIndex(newValue);
  };

  const a11yProps = (number) => ({
    id: `email-parser-tab-${number}`,
    'aria-controls': `email-parser-tabpanel-${number}`,
  });

  const mainReg = /((mailto\:|(news|(ht|f)tp(s?))\:\/\/){1}\S+)/gim;

  console.log(relevant_links_original, 'original links')

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle disableTypography className={classes.header}>
        <div className={classes.headerBlock}>
          <Typography className={classes.title}>
            {date} - {subject}
          </Typography>
          <IconButton
            onClick={handleClose}
            className={classes.closeBtn}
          >
            <CancelIcon />
          </IconButton>
        </div>
        <Tabs
          value={index}
          onChange={handleChange}
          aria-label="email-parser-tabs"
          className={classes.tabs}
          classes={{
            indicator: classes.indicator,
            flexContainer: classes.flexContainer,
          }}
        >
          <Tab
            classes={{
              root: classes.tabWrapper,
              wrapper: classes.tab,
              selected: classes.selected,
            }}
            label="Meta"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...a11yProps(index)}
          />
          <Tab
            classes={{
              root: classes.tabWrapper,
              wrapper: classes.tab,
              selected: classes.selected,
            }}
            label="Header"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...a11yProps(index)}
          />
          <Tab
            classes={{
              root: classes.tabWrapper,
              wrapper: classes.tab,
              selected: classes.selected,
            }}
            label="HTML"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...a11yProps(index)}
          />
          <Tab
            classes={{
              root: classes.tabWrapper,
              wrapper: classes.tab,
              selected: classes.selected,
            }}
            label={`All links (${totallinks})`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...a11yProps(index)}
          />
          <Tab
            classes={{
              root: classes.tabWrapper,
              wrapper: classes.tab,
              selected: classes.selected,
            }}
            label={`Relevant links (${linksfound})`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...a11yProps(index)}
          />
          <Tab
            classes={{
              root: classes.tabWrapper,
              wrapper: classes.tab,
              selected: classes.selected,
            }}
            label={`All attachments (${all_attachment_count})`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...a11yProps(index)}
          />
          <Tab
            classes={{
              root: classes.tabWrapper,
              wrapper: classes.tab,
              selected: classes.selected,
            }}
            label={`Relevant attachments (${attachment_count})`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...a11yProps(index)}
          />
        </Tabs>
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.rootContent,
        }}
      >
        <TabPanel value={index} index={0} id="email-parser-tabpanel">
          <div className={classes.wrp}>
            <div className={classes.left}>
              <Typography className={classes.text}>UID:</Typography>
              <Typography className={classes.text}>Unique id:</Typography>
              <Typography className={classes.text}>Message uid:</Typography>
              <Typography className={classes.text}>
                Sender found:
              </Typography>
              <Typography className={classes.text}>
                House found:
              </Typography>
              <Typography className={classes.text}>
                All links:
              </Typography>
              <Typography className={classes.text}>
                Relevant links:
              </Typography>
              <Typography className={classes.text}>
                All attachments:
              </Typography>
              <Typography className={classes.text}>
                Relevant attachments:
              </Typography>
              <Typography className={classes.text}>
                Sent to java:
              </Typography>
              <Typography className={classes.text}>
                Links downloaded:
              </Typography>
              <Typography className={classes.text}>
                Ignore mail by rule:
              </Typography>
              <Typography className={classes.text}>
                Is desk mail:
              </Typography>
              <Typography className={classes.text}>
                Is mail2pdf:
              </Typography>
              <Typography className={classes.text}>Message id:</Typography>
              <div className={classes.filterBlock}> 
                <Button
                    className={classes.searchBtn}
                    variant="contained"
                    color="primary"
                    onClick={() => handleMailRefresh(message_uid)}
                >
                  Refresh Info
                </Button>
                {isLoading ? (
                  <Button
                  className={classes.searchBtn}
                  style={{ marginTop: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  >
                  Reparse email
                  <LoadingButton
                    className={classes.reparseEmailLoadingButton}
                    size="small"
                    loadingPosition="center"
                    loadingIndicator={
                      <CircularProgress
                        color="inherit"
                        size={12} />
                    }
                    loading={isLoading}
                  >
                  </LoadingButton>
                </Button>
                ) : (
                    <Button
                      className={classes.searchBtn}
                      style={{ marginTop: 20 }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleMailReparse(message_uid)}
                      disabled={isLoading}
                    >
                    Reparse email
                    </Button>
                )
              }
              {(linksfound > 0 || attachment_count > 0) && <>
                  {isLoading ? (
                    <Button
                      className={classes.searchBtn}
                      style={{ marginTop: 20 }}
                      variant="contained"
                      color="primary"
                      disabled={isLoading}
                    >
                      Add to database test
                      <LoadingButton
                        className={classes.reparseEmailLoadingButton}
                        size="small"
                        loadingPosition="center"
                        loadingIndicator={
                          <CircularProgress
                            color="inherit"
                            size={12} />
                        }
                        loading={isLoading}
                      >
                      </LoadingButton>
                    </Button>
                  ) : (
                    <Button
                      className={classes.searchBtn}
                      style={{ marginTop: 20 }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddToDatabase(message_uid)}
                      disabled={isLoading}
                    >
                      Add to database test
                    </Button>
                  )
                  }</>}
              {/* Button for database email saving */}
              </div>
              
            </div>
            <div className={classes.right}>
              <Typography className={classes.textPrimary}>
                {uid}
              </Typography>
               <Typography className={classes.textPrimary}>
                {!unique_id ? ' ' : unique_id}
              </Typography>
               <Typography className={classes.textPrimary}>
                {message_uid}
              </Typography>
              <Typography className={classes.textPrimary}>
                {!emailfrom ? '0' : `1 (${emailfrom})`}
              </Typography>
              <Typography className={classes.textPrimary}>
                {housefound === 0 ? '0' : `1 (${housename})`}
              </Typography>
              <Typography className={classes.textPrimary}>
                {totallinks}
              </Typography>
              <Typography className={classes.textPrimary}>
                {linksfound}
              </Typography>
              <Typography className={classes.textPrimary}>
                {all_attachment_count}
              </Typography>
              <Typography className={classes.textPrimary}>
                {attachment_count}
              </Typography>
              <Typography className={classes.textPrimary}>
                {`${sent_to_java}`}
              </Typography>
              <Typography className={classes.textPrimary}>
                {`${links_downloaded}`}
              </Typography>
              <Typography className={classes.textPrimary}>
                {should_ignore ? "YES" : "NO"}
              </Typography>
              <Typography className={classes.textPrimary}>
                {is_desk_commentary ? "YES" : "NO"}
              </Typography>
              <Typography className={classes.textPrimary}>
                {mail2pdfFlagForJS ? `YES (${mail2pdfFlagForJS})` : "NO"}
              </Typography>
              <Typography className={classes.textPrimary}>
                {message_id}
              </Typography>
            </div>
          </div>
          {mail2pdfFlagForJS ? <div className={classes.fourButtons}>
                <Button
                  className={classes.searchBtn}
                  style={{ marginTop: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={!filesExists.origHtml}
                  onClick={() => window.open(`https://sq7.ee/test/html?filename=${path}${udate}_images/${filename}&orig=1`, "_blank")} 
                >
                  Original HTML
                </Button>
                {isLoading ? (
                  <Button
                  className={classes.searchBtn}
                  style={{ marginTop: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={isLoading || !filesExists.html}
                  >
                    Proccessed HTML
                    <LoadingButton
                    className={classes.reparseEmailLoadingButton}
                    size="small"
                    loadingPosition="center"
                    loadingIndicator={
                      <CircularProgress
                        color="inherit"
                        size={12} />
                    }
                    loading={isLoading}
                    >
                    </LoadingButton>
                  </Button>
                ) : (
                  <Button
                  className={classes.searchBtn}
                  style={{ marginTop: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={isLoading || !filesExists.html}
                  onClick={() => window.open(`https://sq7.ee/test/html?filename=${path}${udate}_images/${filename}`, "_blank")} 
 
                >
                  Proccessed HTML
                </Button>
                )}
                {/*<Button
                  className={classes.searchBtn}
                  style={{ marginTop: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={!filesExists.origPdf}
                  onClick={() => window.open(`https://sq7.ee/test/pdf?filename=${path}${filename}&orig=1`, "_blank")} 
                >
                  Original PDF
                </Button>*/}
                {isLoading ? (
                  <Button
                  className={classes.searchBtn}
                  style={{ marginTop: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={isLoading || !filesExists.pdf}
                  >
                    Proccessed PDF
                    <LoadingButton
                    className={classes.reparseEmailLoadingButton}
                    size="small"
                    loadingPosition="center"
                    loadingIndicator={
                      <CircularProgress
                        color="inherit"
                        size={12} />
                    }
                    loading={isLoading}
                    >
                    </LoadingButton>
                  </Button>
                ) : (
                  <Button
                  className={classes.searchBtn}
                  style={{ marginTop: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={isLoading || !filesExists.pdf}
                  onClick={() => window.open(`https://sq7.ee/test/pdf?filename=${path}${filename}`, "_blank")} 
                >
                  Processed PDF
                </Button>
                )}
                {isLoading ? (
                  <Button
                  className={classes.searchBtn}
                  style={{ marginTop: 20 }}
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  >
                  Add Processed PDF to DB
                  <LoadingButton
                    className={classes.reparseEmailLoadingButton}
                    size="small"
                    loadingPosition="center"
                    loadingIndicator={
                      <CircularProgress
                        color="inherit"
                        size={12} />
                    }
                    loading={isLoading}
                  >
                  </LoadingButton>
                </Button>
                ) : (
                    <Button
                      className={classes.searchBtn}
                      style={{ marginTop: 20 }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleSaveToDatabase(message_uid)}
                      disabled={!filesExists.pdf}
                    >
                    Add Processed PDF to DB
                    </Button>
                )
              }
                </div> : <></>}
          
        </TabPanel>
        {/*Header*/}
        <TabPanel value={index} index={1} id="email-parser-tabpanel">
          <div className={classes.coloredWrp}>
            <pre className={classes.jsonText}>{header}</pre>
          </div>
        </TabPanel>
        {/*html*/}
        <TabPanel value={index} index={2} id="email-parser-tabpanel">
          <div className={classes.coloredWrp}>
            <div className={classes.left}>
              <Typography className={classes.text}>From:</Typography>
              <Typography className={classes.text}>
                Subject:
              </Typography>
            </div>
            <div className={classes.right}>
              <Typography className={classes.textPrimary}>
                {emailfrom}
              </Typography>
              <Typography className={classes.textPrimary}>
                {subject}
              </Typography>
            </div>
          </div>
        </TabPanel>
        {/*All links*/}
        <TabPanel value={index} index={3} id="email-parser-tabpanel">
        <div className={classes.wrp}>
            {totallinks > 0 ? (
              <ol className={classes.linksWrp}>
                {Object.keys(all_links)
                  ? Object.keys(all_links).map((key, i) => {
                      const link = all_links[key];
                      const linkObject = all_raw_links.find(rawLink => rawLink.href === link)
                      return (
                        <li key={i}>
                          <p>
                            URL:{' '}
                            <a target="_blank" href={link}>
                              {link}
                            </a>
                          </p>
                          <p>TEXT: {linkObject ? linkObject.title : ''}</p>
                          <p>
                            FULL:{' '}
                            {linkObject ? linkObject.full : ''}
                          </p>
                        </li>
                      );
                    })
                  : ''}
              </ol>
            ) : (
              <Typography className={classes.text}>
                No links found.
              </Typography>
            )}
          </div>
        </TabPanel>
        {/*Relevant links*/}
        <TabPanel value={index} index={4} id="email-parser-tabpanel">
        <div className={classes.wrp}>
            {relevantlinks ? (
              <ol className={classes.linksWrp}>
                {relevantlinks !== 'NULL\n' &&
                attachmentsfound === 1 ? (
                  <Typography
                    style={{ color: 'red', marginBottom: 10 }}
                  >
                    NB! Found these relevant links but not downloading
                    them because found relevant PDF attachment
                  </Typography>
                ) : (
                  ''
                )}
               
              </ol>
            ) : (
              <Typography className={classes.text}>
                No relevant links found.
              </Typography>
            )}
          </div>
        </TabPanel>
        <TabPanel value={index} index={4} id="email-parser-tabpanel">
          <div className={classes.wrp}>
           {relevantlinks ? (
              <ol className={classes.linksWrp}>
                {relevantlinks !== 'NULL\n' &&
                attachmentsfound === 1 ? (
                  <Typography
                    style={{ color: 'red', marginBottom: 10 }}
                  >
                    NB! Found these relevant links but not downloading
                    them because found relevant PDF attachment
                  </Typography>
                ) : (
                  ''
                )}
                {relevant_links_original
                  ? relevant_links_original.map((link, i) => {
                    const linkObject = relevant_raw_links.find(rawLink => rawLink.href === link );
                    const processedLink = relevantlinks[i]
                  
                    return (
                         <li key={i}>
                          <p>
                            URL:{' '}
                            <a target="_blank" href={processedLink}>
                              {processedLink}
                            </a>
                          </p>
                          <p>TEXT: {linkObject ? linkObject.title : ''}</p>
                          <p>
                            FULL:{' '}
                            {linkObject
                              ? linkObject.full : ''}
                          </p>
                        </li>
                    )
                  }) : relevantlinks.map((relevantLink, i) => {
                      console.log(relevantLink)
                      const linkObject = relevant_raw_links.find(link => link.href === relevantLink)
                  
                
                      return (
                        <li key={i}>
                          <p>
                            URL:{' '}
                            <a target="_blank" href={relevantLink}>
                              {relevantLink}
                            </a>
                          </p>
                          <p>TEXT: {linkObject ? linkObject.title : ''}</p>
                          <p>
                            FULL:{' '}
                            {linkObject
                              ? linkObject.full : ''}
                          </p>
                        </li>
                      );
                    })}
              </ol>
            ) : (
              <Typography className={classes.text}>
                No relevant links found.
              </Typography>
            )}
          </div>
        </TabPanel>
        {/*All attachments*/}
        <TabPanel value={index} index={5} id="All attachments">
          <div className={classes.wrp}>
            <Typography className={classes.text}>
              {Object.values(attachments || []).length ? (
                <ol>
                  {Object.values(attachments).map((at) => (
                    <li key={at.content_name}>
                      <p>{at.name}</p>
                      <ul>
                        <li>
                          <strong>extension</strong>:{' '}
                          {at.extension}
                        </li>
                        <li>
                          <strong>content_id</strong>:{' '}
                          {at.id}
                        </li>
                        <li>
                          <strong>content_type</strong>: {at.subtype}
                        </li>
                        <li>
                          <strong>content_type</strong>:{' '}
                          {at.extension}
                        </li>
                        <li>
                          <strong>content_id</strong>: {at.id}
                        </li>
                        <li>
                          <strong>content_subtype</strong>:{' '}
                          {at.subtype}
                        </li>
                        <li>
                          <strong>final</strong>: {at.final}
                        </li>
                      </ul>
                    </li>
                  ))}
                </ol>
              ) : (
                'No attachments found.'
              )}
            </Typography>
          </div>
        </TabPanel>
        {/*Relevant attachments*/}
        <TabPanel value={index} index={6} id="Relevant attachments">
          <div className={classes.wrp}>
            <Typography className={classes.text}>
              {Object.values(attachments || []).length ? (
                <ol>
                  {Object.values(attachments).filter(e => e.extension === 'pdf').length ? Object.values(attachments).filter(e => e.extension === 'pdf').map((at) => (
                    <li key={at.content_name}>
                      <p>{at.name}</p>
                      <ul>
                        <li>
                          <strong>extension</strong>:{' '}
                          {at.extension}
                        </li>
                        <li>
                          <strong>content_id</strong>:{' '}
                          {at.id}
                        </li>
                        <li>
                          <strong>content_type</strong>: {at.subtype}
                        </li>
                        <li>
                          <strong>final</strong>: {at.extension.toLowerCase() === 'pdf' ? "PDF" : "NOT-PDF"}
                        </li>
                      </ul>
                    </li>
                  )) : 'No attachments found.'}
                  {Object.values(attachments).filter(
                    (e) => e.extension === 'pdf'
                  ).length
                    ? Object.values(attachments)
                        .filter((e) => e.extension === 'pdf')
                        .map((at) => (
                          <li key={at.content_name}>
                            <p>{at.name}</p>
                            <ul>
                              <li>
                                <strong>content_type</strong>:{' '}
                                {at.extension}
                              </li>
                              <li>
                                <strong>content_id</strong>: {at.id}
                              </li>
                              <li>
                                <strong>content_subtype</strong>:{' '}
                                {at.subtype}
                              </li>
                              <li>
                                <strong>final</strong>:{' '}
                                {at.extension.toLowerCase() === 'pdf'
                                  ? 'PDF'
                                  : 'NOT-PDF'}
                              </li>
                            </ul>
                          </li>
                        ))
                    : 'No attachments found.'}
                </ol>
              ) : (
                'No attachments found.'
              )}
            </Typography>
          </div>
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
};

EmailParserModal.propTypes = propTypes;
EmailParserModal.defaultProps = defaultProps;

export default withStyles(styles)(EmailParserModal);
