import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  USER_LOGGED,
} from '../../actions/app';

export const showNotification = (data) => (dispatch) => {
  const payload = data;
  return dispatch({
    type: SHOW_NOTIFICATION,
    payload,
  });
};

export const hideNotification = () => (dispatch) =>
  dispatch({
    type: HIDE_NOTIFICATION,
  });

export const showUserName = (data) => (dispatch) => {
  const payload = data;
  return dispatch({
    type: USER_LOGGED,
    payload,
  });
};
