import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Pagination as MuiPagination } from '@material-ui/lab';

const propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  changePage: PropTypes.func,
};

const defaultProps = {
  changePage: () => {},
};

const styles = {
  root: {},
};

const Pagination = (props) => {
  const { currentPage, itemsCount, itemsPerPage, changePage } = props;

  const handleChange = (event, page) => {
    changePage(page - 1);
  };

  return (
    <MuiPagination
      page={currentPage + 1}
      count={Math.ceil(itemsCount / itemsPerPage)}
      variant="outlined"
      onChange={handleChange}
    />
  );
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default withStyles(styles)(Pagination);
