import React from 'react';
import { createMuiTheme, fade } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { HighlightOff } from '@material-ui/icons';
import { CheckboxIcon, CheckedIcon } from '../icons';

const closeOutlined = <HighlightOff />;
const check = <CheckboxIcon />;
const checked = <CheckedIcon />;

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#303E4E',
      dark: '#303E4E',
    },
    secondary: {
      main: '#303E4E',
      dark: '#303E4E',
    },
    error: {
      main: '#f05452',
    },
    warning: {
      main: '#FC9300',
    },
    info: {
      main: '#3BB2FC',
    },
    success: {
      main: '#18A871',
    },
  },

  spacing: (factor) =>
    [0, '0.5rem', '1rem', '1.5rem', '2rem', '3rem'][factor],

  typography: {
    fontFamily: [
      'Inter',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  props: {
    MuiMenuItem: {
      disableRipple: true,
    },
    MuiTooltip: {
      arrow: true,
    },
    MuiChip: {
      deleteIcon: closeOutlined,
    },
    MuiCheckbox: {
      icon: check,
      checkedIcon: checked,
    },
  },

  overrides: {
    MuiSvgIcon: {
      root: {
        overflow: 'visible',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '0.88rem',
        fontSize: '1rem',
        minHeight: '3rem',
        minWidth: '7rem',
        textTransform: 'none',
        [breakpoints.down('xs')]: {
          borderRadius: '1rem',
        },
      },
      contained: {
        '&$disabled': {
          backgroundColor: '#EDF2F7',
          color: '#A0AEC0',
        },
      },
      outlined: {
        borderColor: '#E2E8F0',
        color: '#303E4E',
      },
      containedPrimary: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        borderWidth: '0.16rem',
        borderColor: '#F05452',
        '&:hover': {
          borderWidth: '0.16rem',
        },
      },
      text: {
        color: '#A0AEC0',
        padding: '0.48rem 0.96rem',
      },
      sizeSmall: {
        minHeight: '2.24rem',
        minWidth: '3.84rem',
        fontSize: '1.12rem',
        padding: '0.32rem 0.72rem',
        borderRadius: '0.64rem',
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: '0.88rem',
        padding: 0,
        width: '2.56rem',
        height: '2.56rem',
        color: '#303E4E',
        backgroundColor: '#EDF2F7',
        '&$disabled': {
          color: '#CBD5E0',
        },
      },
    },
    MuiPagination: {
      ul: {
        '& li:first-child button': {
          borderRadius: '0.8rem 0 0 0.8rem',
          borderLeft: '0.08rem solid #E2E8F0',
        },
        '& li:last-child button': {
          borderRadius: '0 0.8rem 0.8rem 0',
        },
      },
    },
    MuiPaginationItem: {
      root: {
        borderRadius: 0,
        margin: 0,
        minWidth: '3.2rem',
        height: '3.2rem',
        [breakpoints.down('xs')]: {
          minWidth: '2.88rem',
          height: '2.88rem',
        },
      },
      outlined: {
        borderColor: '#E2E8F0',
        borderLeft: 0,
      },
      page: {
        '&$disabled': {
          opacity: 1,
        },
        '&$selected': {
          backgroundColor: '#303E4E',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#303E4E !important',
            color: '#fff',
          },
        },
        '&:hover': {
          backgroundColor: '#F7FAFC',
        },
      },
      ellipsis: {
        borderRight: '0.08rem solid #E2E8F0',
        height: '2.56rem',
        display: 'flex',
        lineHeight: '2.16rem',
        justifyContent: 'center',
      },
    },
    MuiAvatar: {
      root: {
        width: '3rem',
        height: '3rem',
        border: '0.08rem solid #E2E8F0',
        textDecoration: 'none',
      },
      colorDefault: {
        color: '#748297',
        backgroundColor: '#F7FAFC',
        borderColor: '#E2E8F0',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(48, 62, 78, .32)',
      },
    },
    MuiDialog: {
      root: {
        zIndex: '9999 !important',
      },
      paper: {
        borderRadius: '1.5rem',
        margin: '1.75rem',
        boxShadow: 'none',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '1rem 3rem',
        [breakpoints.down('xs')]: {
          padding: '0.5rem 1.5rem',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '3rem 3rem 1rem 3rem',
        fontSize: '2rem',
        fontWeight: 600,
        color: '#303E4E',
        [breakpoints.down('xs')]: {
          padding: '1.5rem 1.5rem 1rem 1.5rem',
          fontSize: '1.5rem',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: '1rem 3rem 3rem 3rem',
        [breakpoints.down('xs')]: {
          padding: '1rem 1.5rem 1.5rem 1.5rem',
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: '2rem',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover': {
          '& $notchedOutline': {
            borderColor: '#000000',
          },
          '&$disabled': {
            '& $notchedOutline': {
              borderColor: '#E2E8F0',
            },
          },
        },
        '&$focused': {
          '& $notchedOutline': {
            borderColor: '#000000',
            borderWidth: '0.08rem',
          },
        },
        '& legend': {
          fontSize: '1.13rem',
        },
        '&$disabled': {
          '& $notchedOutline': {
            borderColor: '#E2E8F0',
          },
        },
      },
      adornedEnd: {
        paddingRight: '0.48rem',
      },
      input: {
        paddingTop: '1.08rem',
        paddingBottom: '1.08rem',
        fontSize: '1rem',
        borderRadius: '0.88rem',
        color: '#303E4E',
        fontWeight: 400,
        '&$disabled': {
          color: '#748297',
        },
        '&::placeholder': {
          color: '#A0AEC0',
          opacity: '1',
        },
      },
      multiline: {
        paddingTop: '1.125rem',
        paddingBottom: '1.125rem',
      },
      notchedOutline: {
        borderColor: '#000000',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#A0AEC0',
        fontSize: '1rem',
        lineHeight: 0.85,
        fontWeight: 400,
        '& .light': {
          color: '#A0AEC0',
        },
      },
      outlined: {
        transform: 'translate(1rem, 1.25rem) scale(1)',
        '&$shrink': {
          fontSize: '1.32rem',
          color: '#111111',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#303E4E',
        },
      },
      focused: {},
    },
    MuiFormHelperText: {
      root: {
        fontSize: '1rem',
        color: '#A0AEC0',
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiInputAdornment: {
      root: {
        color: '#A0AEC0',
      },
    },
    MuiSelect: {
      select: {
        lineHeight: '1.2',
        '&:focus': {
          backgroundColor: 'transparent',
        },
        '&$outlined': {
          paddingRight: '3.84rem !important',
        },
      },

      icon: {
        right: '0.84rem',
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: '1.75rem',
        color: '#748297',
      },
      iconOpen: {
        transform: 'translateY(-50%) rotate(180deg)',
      },
      iconOutlined: {
        right: '1rem',
        fontSize: '2rem',
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        backgroundColor: 'transparent',
      },
    },
    MuiChip: {
      root: {
        height: '2.24rem',
        backgroundColor: '#FAFBFC',
        border: '0.08rem solid #EDF2F7',
        borderRadius: '0.48rem',
        '& $avatar': {
          marginLeft: '0 !important',
          borderRadius: '0.63rem',
          height: '2.1rem',
          width: '2.1rem',
        },
      },
      avatar: {},
      label: {
        color: '#4A5568',
        paddingLeft: '0.64rem',
        paddingRight: '0.64rem',
        fontSize: '0.96rem',
      },
      deleteIcon: {
        color: '#A0AEC0',
        width: '1.25rem',
        height: '1.25rem',
      },
    },
    MuiTabs: {
      root: {
        minHeight: '2.8rem',
        borderBottom: '0.08rem solid #E2E8F0',
      },
      indicator: {
        borderRadius: '0.32rem 0.32rem 0rem 0rem',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        minWidth: '3rem !important',
        fontSize: '0.88rem !important',
        minHeight: '2.8rem',
      },
      textColorInherit: {
        color: '#748297',
        opacity: '1',
        fontWeight: '400',
        '&$selected': {
          color: '#111111',
          fontWeight: '500',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '.9rem',
        backgroundColor: '#303E4E',
        borderRadius: '0.48rem',
      },
      popper: {
        zIndex: 9991,
      },
      popperArrow: {
        '&[x-placement*="bottom"] $arrow': {
          borderTopLeftRadius: '0.24rem',
          marginTop: '-0.375rem',
        },
        '&[x-placement*="top"] $arrow': {
          borderBottomRightRadius: '0.24rem',
          marginBottom: '-0.375rem',
        },
        '&[x-placement*="left"] $arrow': {
          borderTopRightRadius: '0.24rem',
          marginRight: '-0.375rem',
        },
        '&[x-placement*="right"] $arrow': {
          borderBottomLeftRadius: '0.24rem',
          marginLeft: '-0.375rem',
        },
      },
      arrow: {
        color: '#303E4E',
        width: '1rem !important',
        height: '1rem !important',
        background: '#303E4E',
        transform: 'rotate(45deg)',
        zIndex: '-9',
        '&:before': {
          display: 'none',
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
        '&$expanded': {
          margin: 0,
          paddingBottom: '1rem',
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: '3rem',
        padding: 0,
        '&$expanded': {
          minHeight: '3rem',
        },
      },
      content: {
        margin: '0.75rem 0',
        '&$expanded': {
          margin: '0.75rem 0',
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: 0,
      },
    },
    MuiPopover: {
      root: {
        zIndex: '9999 !important',
      },
      paper: {
        boxShadow: '0rem 0.48rem 1.28rem rgba(74, 85, 104, 0.08)',
        border: '0.08rem solid #000000',
        borderRadius: 0,
        marginTop: '0.75rem',
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 0,
        boxShadow: '0rem 0.48rem 1.28rem rgba(74, 85, 104, 0.08)',
        border: '0.08rem solid #000000',
      },
    },
    MuiList: {
      padding: {
        padding: '0.48rem',
        paddingTop: '0.48rem',
        paddingBottom: '0.48rem',
        '& li': {
          paddingLeft: '0.8rem',
          paddingRight: '0.8rem',
        },
        // '& li:hover': {
        //   backgroundColor: '#F7FAFC',
        // },
      },
    },
    // MuiListItem: {
    //   button: {
    //     '&:hover': {
    //       backgroundColor: '#FAFBFC',
    //     },
    //   },
    // },
    MuiMenuItem: {
      root: {
        color: '#303E4E',
        minHeight: '3rem',
        // '&:hover': {
        //   backgroundColor: '#F7FAFC',
        // },
        // '&$selected': {
        //   backgroundColor: '#EDF2F7',
        // },
        // '&$selected:hover': {
        //   backgroundColor: '#EDF2F7',
        // },
      },
      // list: {
      //   padding: 0,
      // },
    },
    MuiDivider: {
      backgroundColor: '#E2E8F0',
    },
    MuiAutocomplete: {
      popper: {
        zIndex: 9999,
      },
      paper: {
        boxShadow: 'none',
        border: '0.08rem solid #000000',
        borderRadius: 0,
      },
      listbox: {
        padding: '0.48rem',
      },
      option: {
        padding: '0.48rem !important',
        minHeight: '2.88rem !important',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        '&>span': {
          width: '1.28rem',
          height: '1.28rem',
        },
        '&[data-focus="true"]': {
          backgroundColor: 'transparent',
        },
        '&[aria-selected="true"]': {
          backgroundColor: 'transparent',
        },
      },
      clearIndicator: {
        width: '1.28rem',
        height: '1.28rem',
        backgroundColor: 'transparent',
      },
      popupIndicator: {
        width: '3rem',
        height: '3rem',
        backgroundColor: '#fff',
        color: '#748297',
        border: 0,
        marginRight: 0,
        '&:hover': {
          backgroundColor: '#fff',
        },
      },
      inputRoot: {
        flexWrap: 'nowrap',
        overflow: 'hidden',
        padding: '0.48rem 3.2rem 0.48rem 0.48rem !important',
        '& $input': {
          padding: '0.48rem 0.32rem !important',
        },
      },
      endAdornment: {
        top: 0,
        right: '0 !important',
      },
    },
    MuiPickersClock: {
      container: {
        margin: 0,
      },
      clock: {
        backgroundColor: '#FAFBFC',
        border: '0.08rem solid #EDF2F7',
        marginTop: '1rem',
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: '#303E4E',
        '&:hover': {
          backgroundColor: '#ff0000 !important',
        },
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        display: 'none',
      },
      daysHeader: {
        marginBottom: '1rem',
      },
      dayHeader: {
        marginTop: '0.8rem',
      },
      dayLabel: {
        color: '#748297',
        fontSize: '1rem',
        margin: '0',
        width: '3rem',
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        width: '23.68rem',
        minHeight: '18.4rem',
      },
    },
    MuiPickersBasePicker: {
      container: {
        // padding: '0 1.5rem',
      },
      pickerView: {
        minWidth: '20.8rem',
        minHeight: '16rem',
        paddingTop: '1.5rem',
      },
    },
    MuiPickersMonthSelection: {
      container: {
        width: 'auto',
        justifyContent: 'center',
        marginTop: '-1.04rem',
      },
    },
    MuiPickersMonth: {
      root: {
        height: '3.5rem',
        borderRadius: '1rem',
        maxWidth: '7.28rem',
        color: '#303E4E',
        marginTop: '0.16rem',
        marginBottom: '0.16rem',
        '&:hover': {
          color: '#F05452',
          backgroundColor: fade('#F05452', 0.12),
        },
        '&:focus': {
          color: '#F05452',
          backgroundColor: fade('#F05452', 0.12),
        },
      },
      monthSelected: {
        backgroundColor: '#F05452',
        color: '#fff',
        fontSize: '1rem',
      },
      monthDisabled: {
        color: '#A0AEC0',
      },
    },
    MuiPickersCalendar: {
      week: {
        marginTop: '0.16rem',
        '& > div:first-child .daySelected': {
          borderRadius: '0.75rem 0 0 0.75rem',
        },
        '& > div:last-child .daySelected': {
          borderRadius: '0 0.75rem 0.75rem 0',
        },
      },
      transitionContainer: {
        minHeight: '19.2rem',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        fontWeight: '500',
      },
    },
  },
});

export default theme;
