import React from 'react';
import { SvgIcon } from '@material-ui/core';

const MAilBoxIcon = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M19 4H5a3 3 0 00-3 3v10a3 3 0 003 3h14a3 3 0 003-3V7a3 3 0 00-3-3zM5 6h14a1 1 0 011 1l-8 4.88L4 7a1 1 0 011-1zm15 11a1 1 0 01-1 1H5a1 1 0 01-1-1V9.28l7.48 4.57a1 1 0 001 0L20 9.28z" />
    </SvgIcon>
  );
};

export default MAilBoxIcon;
