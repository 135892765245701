import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, withStyles } from '@material-ui/core';

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.instanceOf(Object).isRequired,
  isPDF: PropTypes.bool,
};

const defaultProps = {
  className: '',
  isPDF: false,
};

const styles = (theme) => ({
  switch: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#EDF2F7',
    borderRadius: '0.88rem',
    padding: '0.25rem',
  },
  btn: {
    minWidth: '4.2rem',
    minHeight: '1.75rem',
    color: '#A0AEC0',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0.64rem',
    },
  },
  btnActive: {
    color: '#4A5568',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
});

const ParserSwitch = (props) => {
  const { classes, className, isPDF } = props;

  const history = useHistory();

  const handlePDFViewer = () => {
    history.push('/research');
  };

  const handleEmailParser = () => {
    history.push('/email');
  };

  const handleHistoryViewer = () => {
    history.push('/history');
  };

  const handleHouseViewer = () => {
    history.push('/house');
  };

  const handleProxyViewer = () => {
    history.push('/proxy');
  };

  const [active, setActive] = useState({
    research: false,
    email: false,
    history: false,
    house: false,
  });

  useEffect(() => {
    if (window.location.pathname.includes('research')) {
      setActive({
        ...active,
        research: true,
      });
    }
    if (window.location.pathname.includes('email')) {
      setActive({
        ...active,
        email: true,
      });
    }
    if (window.location.pathname.includes('history')) {
      setActive({
        ...active,
        history: true,
      });
    }
    if (window.location.pathname.includes('house')) {
      setActive({
        ...active,
        house: true,
      });
    }
    if (window.location.pathname.includes('proxy')) {
      setActive({
        ...active,
        proxy: true,
      });
    }
  }, [window.location.pathname]);

  return (
    <div className={cn(classes.switch, className)}>
      <Button
        className={cn(
          classes.btn,
          active.research && classes.btnActive
        )}
        onClick={handlePDFViewer}
      >
        Research
      </Button>
      <Button
        className={cn(classes.btn, active.email && classes.btnActive)}
        onClick={handleEmailParser}
      >
        Email
      </Button>
      <Button
        className={cn(
          classes.btn,
          active.history && classes.btnActive
        )}
        onClick={handleHistoryViewer}
      >
        History
      </Button>
      <Button
        className={cn(classes.btn, active.house && classes.btnActive)}
        onClick={handleHouseViewer}
      >
        House
      </Button>
      <Button
        className={cn(classes.btn, active.proxy && classes.btnActive)}
        onClick={handleProxyViewer}
      >
        Proxy
      </Button>
    </div>
  );
};

ParserSwitch.propTypes = propTypes;
ParserSwitch.defaultProps = defaultProps;

export default withStyles(styles)(ParserSwitch);
