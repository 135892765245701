import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import superagent from 'superagent';
import { useDispatch } from 'react-redux';
import {
  Container,
  withStyles,
  Hidden,
  Button,
  Typography,
} from '@material-ui/core';
import {
  Column,
  Table,
  AutoSizer,
  InfiniteLoader,
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import { showNotification } from '../redux/modules/app/appActions';
import { InboxIcon } from '../icons';
import EmailParserModal from './EmailParserModal';
import Loader from '../uikits/Loader';

const propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  isAdmin: PropTypes.bool,
  isShow: PropTypes.bool,
  loadMoreRows: PropTypes.func,
};

const defaultProps = {
  isAdmin: false,
  isShow: false,
  loadMoreRows: () => { },
};

const styles = (theme) => ({
  wrp: {
    height: 'calc(100% - 15.04rem)',
    marginTop: '1.28rem',
    marginBottom: '1.28rem',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      overflowY: 'auto',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
  },
  container: {
    height: '100%',
  },
  table: {
    width: '100%',
  },
  date: {
    display: 'inline-flex',
    alignItems: 'center',
    textAlign: 'left',
  },
  link: {
    color: '#3179F5',
    textDecoration: 'none',
  },
  tableHead: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.28rem',
  },
  card: {
    width: '100%',
    maxHeight: '4rem',
    justifyContent: 'start',
    backgroundColor: '#FFFFFF',
    border: '0.08rem solid #E2E8F0',
    marginBottom: '1rem',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  text: {
    color: '#4A5568',
  },
  subText: {
    color: '#748297',
  },
  emptyWrp: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '0 auto',
  },
  emptyIcon: {
    width: '2.24rem',
    height: '2.24rem',
    color: '#4A5568',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
  emptyText: {
    fontSize: '1.44rem',
    color: '#303E4E',
    marginBottom: '0.8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
});

const FILES_MOCK = {
  html: (path, udate, filename) =>
    `/test/html?filename=${path}${udate}_images/${filename}`,
  origHtml: (path, udate, filename) =>
    `/test/html?filename=${path}${udate}_images/${filename}&orig=1`,
  pdf: (path, udate, filename) =>
    `/test/pdf?filename=${path}${filename}`,
  origPdf: (path, udate, filename) =>
    `/test/pdf?filename=${path}${filename}&orig=1`,
};

function ParserTable(props) {
  const {
    classes,
    isAdmin,
    output,
    value,
    isShow,
    loadMoreRows,
    handleParseAttachments,
    handleParseRelevantLinks,
    handleParseAll,
  } = props;

  const initialFiles = { html: false, origHtml: false, pdf: false, origPdf: false }
  const [isOpen, setIsOpen] = useState(false);
  const [emailParser, setEmailParser] = useState(null);
  const [emails, setEmails] = useState([]);
  const [filesExists, setFilesExists] = useState(initialFiles)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch();
  const ShowNotification = (action) =>
    dispatch(showNotification(action));

  const handleFilesCheck = (emailData) => {
    Object.entries(FILES_MOCK).forEach((file) =>
      superagent
        .get(file[1](emailData.path, emailData.udate, emailData.filename))
        .withCredentials()
        .set("Content-Type", "application/json")
        .then((res) => {
          if (res.status === 200)
            setFilesExists(prev => ({ ...prev, [file[0]]: true }))
        })
        .catch(() => { })
    );
  }

  useEffect(() => {
    setEmails((output && JSON.parse(output) || []));
    console.log(emails);
  }, [output])

  const handleModalOpen = (data) => {
    if (data.rowData) {
      if(!isLoading){
        handleMailReparse(data.rowData.message_uid);
      }
      handleFilesCheck(data.rowData);
      setIsOpen(true);
      setEmailParser(data.rowData);
      return;
    }
    setIsOpen(true);
    setEmailParser(data);
  };

  const handleMailRefresh = (message_uid) => {
    if (message_uid) {
      setIsLoading(true)
      console.log(value, 'request query parameters');
      superagent
        .get(
          `/xof/parser/json?mailbox=${value.mailbox}&sender=${value.sender}&house=${value.house}&number_to_show=${value.count}&process_mode=${value.mode}&message_uid=${message_uid}&filterscount=0&groupscount=0&pagenum=0&pagesize=50&recordstartindex=0&recordendindex=50&featureClass=P&style=full&maxRows=50&_=1626481059982`
        )
        .withCredentials()
        .set('Content-Type', 'application/json')
        .then((res) => {
          const reparsedEmail = res.text.substring(res.text.indexOf('['));
          const jsonDecode = JSON.parse(reparsedEmail)[0];
          setEmailParser(jsonDecode);
          console.log(jsonDecode);
          ShowNotification({
            title: 'Info',
            message: "Email parsed successfully",
          });
        }).catch((err) => {
          ShowNotification({
            title: 'Error',
            message: err.response ? err.response.text : err.message,
          });
        }).finally(() => {
          setIsLoading(false)
        })
    }
  }
  
  const handleSaveToDatabase=(message_uid)=>{
    if (message_uid) {
      setIsLoading(true)
      console.log(value, 'request query parameters');
      superagent
        .get(
          `/xof/parser/json?mailbox=${value.mailbox}&sender=${value.sender}&house=${value.house}&number_to_show=${value.count}&process_mode=3&message_uid=${message_uid}&filterscount=0&groupscount=0&pagenum=0&pagesize=50&recordstartindex=0&recordendindex=50&featureClass=P&style=full&maxRows=50&_=1626481059982&forceAddToDatabaseJS=1`
        )
        .withCredentials()
        .set('Content-Type', 'application/json')
        .then((res) => {
          console.log(res.text, 'response data');
          const reparsedEmail = res.text.substring(res.text.indexOf('['));
          const jsonDecode = JSON.parse(reparsedEmail)[0];
          
          if(!filesExists.origHtml || !filesExists.origPdf) {
            handleFilesCheck(jsonDecode); 
          }
          setEmailParser(jsonDecode);
          ShowNotification({
            title: 'Info',
            message: "Email parsed successfully",
          });
        }).catch((err) => {
          ShowNotification({
            title: 'Error',
            message: err.response ? err.response.text : err.message,
          });
        }).finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleAddToDatabase = (message_uid) => {
    if (message_uid) {
      setIsLoading(true)
      console.log(value, 'request query parameters');
      superagent
        .get(
          `/xof/parser/json?mailbox=${value.mailbox}&sender=${value.sender}&house=${value.house}&number_to_show=${value.count}&process_mode=3&message_uid=${message_uid}&filterscount=0&groupscount=0&pagenum=0&pagesize=50&recordstartindex=0&recordendindex=50&featureClass=P&style=full&maxRows=50&_=1626481059982&forceAddToDatabaseJS=0&treat_as_new_incoming_mail=1`
        )
        .withCredentials()
        .set('Content-Type', 'application/json')
        .then((res) => {
          console.log(res.text, 'response data');
          const reparsedEmail = res.text.substring(res.text.indexOf('['));
          const jsonDecode = JSON.parse(reparsedEmail)[0];

          if (!filesExists.origHtml || !filesExists.origPdf) {
            handleFilesCheck(jsonDecode);
          }
          setEmailParser(jsonDecode);
          ShowNotification({
            title: 'Info',
            message: "Email added to database successfully",
          });
        }).catch((err) => {
          ShowNotification({
            title: 'Error',
            message: err.response ? err.response.text : err.message,
          });
        }).finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleMailReparse = (message_uid) => {
    if (message_uid) {
      setIsLoading(true)
      console.log(value, 'request query parameters');
      superagent
        .get(
          `/xof/parser/json?mailbox=${value.mailbox}&sender=${value.sender}&house=${value.house}&number_to_show=${value.count}&process_mode=3&message_uid=${message_uid}&filterscount=0&groupscount=0&pagenum=0&pagesize=50&recordstartindex=0&recordendindex=50&featureClass=P&style=full&maxRows=50&_=1626481059982&forceAddToDatabaseJS=0`
        )
        .withCredentials()
        .set('Content-Type', 'application/json')
        .then((res) => {
          console.log(res.text, 'response data');
          const reparsedEmail = res.text.substring(res.text.indexOf('['));
          const jsonDecode = JSON.parse(reparsedEmail)[0];

          if(!filesExists.origHtml || !filesExists.origPdf) {
            handleFilesCheck(jsonDecode); 
          }
          setEmailParser(jsonDecode);
          ShowNotification({
            title: 'Info',
            message: "Email parsed successfully",
          });
        }).catch((err) => {
          ShowNotification({
            title: 'Error',
            message: err.response ? err.response.text : err.message,
          });
        }).finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleModalClose = () => {
    setIsOpen(false);
    setEmailParser(null);
    setFilesExists(initialFiles)
  };

  const parsedEmailDate = (file_create_time) => {
    return `${new Date(file_create_time)
        .getDate()
        .toString()
        .padStart(2, '0')}-${(new Date(file_create_time).getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${new Date(file_create_time)
          .getFullYear()
          .toString()
          .padStart(4, '0')} ${new Date(file_create_time)
            .getHours()
            .toString()
            .padStart(2, '0')}:${new Date(file_create_time)
              .getMinutes()
              .toString()
              .padStart(2, '0')}:${new Date(file_create_time)
                .getSeconds()
                .toString()
                .padStart(2, '0')}`;
  };


  return (
    <Container className={classes.wrp}>
      <div className={classes.container}>
        <Hidden smDown>
          <InfiniteLoader
            isRowLoaded={({ index }) => !!emails[index]}
            loadMoreRows={loadMoreRows}
            rowCount={1000}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer>
                {({ width, height }) => (
                  <Table
                    className={classes.table}
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    width={width}
                    height={height}
                    headerHeight={48}
                    rowHeight={30}
                    rowCount={emails.length}
                    onRowClick={handleModalOpen}
                    noRowsRenderer={() => {
                      return isShow ? (
                        <div className={classes.emptyWrp}>
                          <Loader className={classes.loader} />
                        </div>
                      ) : (
                        <div className={classes.emptyWrp}>
                          <InboxIcon className={classes.emptyIcon} />
                          <Typography className={classes.emptyText}>
                            0 results
                          </Typography>
                          <Typography className={classes.subText}>
                            Please select a mailbox in order to
                            initiate search.
                          </Typography>
                        </div>
                      );
                    }}
                    rowGetter={({ index }) => emails[index]}
                  >
                    <Column
                      disableSort
                      label="DATE"
                      dataKey="date"
                      width={width}
                      flexGrow={0.95}
                      style={{ minWidth: '12.8rem' }}
                      cellRenderer={({ cellData }) => {
                        return parsedEmailDate(cellData);
                      }}
                    />
                    <Column
                      disableSort
                      label="SUBJECT"
                      dataKey="subject"
                      width={width}
                      flexGrow={1}
                      flexShrink={0.73}
                      style={{ color: '#3179F5' }}
                      cellRenderer={({ cellData }) => {
                        return cellData.length < 60
                          ? cellData
                          : `${typeof cellData === 'string' ? cellData.substr(0, 65) : ''}...`;
                      }}
                    />
                    <Column
                      disableSort
                      label="FROM"
                      dataKey="emailfrom"
                      width={width}
                      flexGrow={1}
                      flexShrink={1.05}
                    />
                    <Column
                      disableSort
                      label="HOUSE"
                      dataKey="housename"
                      width={width}
                      flexGrow={1}
                      flexShrink={1.05}
                      cellRenderer={({ cellData }) => {
                        return cellData
                          ? cellData
                          : '0'
                      }}
                    />
                    <Column
                      disableSort
                      label="mail2pdf"
                      dataKey="mail2pdfFlagForJS"
                      width={width}
                      flexGrow={1}
                      // flexShrink={0.8}
                      style={{ textAlign: 'center' }}
                      headerStyle={{ textAlign: 'center' }}
                      cellRenderer={({ cellData }) => {
                        return cellData ? `YES (${cellData})` : "No";
                      }}
                    />
                    <Column
                      disableSort
                      label="LINKS"
                      dataKey="totallinks"
                      width={width}
                      flexGrow={1}
                      flexShrink={1.05}
                      style={{ textAlign: 'center' }}
                      headerStyle={{ textAlign: 'center' }}
                    />
                    <Column
                      disableSort
                      label="RELEVANT LINKS"
                      dataKey="relevantlinks"
                      cellRenderer={({ cellData }) => {
                        return cellData ? cellData.length : 0
                      }}
                      width={width}
                      flexGrow={1}
                      style={{ textAlign: 'center' }}
                      headerStyle={{ textAlign: 'center' }}
                    />
                    <Column
                      disableSort
                      label="ATTACHMENTS"
                      dataKey="attachmentsfound"
                      width={width}
                      flexGrow={1}
                      style={{ textAlign: 'center' }}
                      headerStyle={{ textAlign: 'center' }}
                    />
                      <Column
                      disableSort
                      label="IGNORE"
                      dataKey="should_ignore"
                      width={width}
                      flexGrow={1}
                      style={{ textAlign: 'center' }}
                      headerStyle={{ textAlign: 'center' }}
                      cellRenderer={({ cellData }) => {
                        return cellData ? 'YES' : 'NO'
                      }}
                    />
                  </Table>
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </Hidden>
        <Hidden mdUp>
          {isShow && (
            <div className={classes.emptyWrp}>
              <Loader className={classes.loader} />
            </div>
          )}
          {!isShow &&
            emails.length > 0 &&
            emails.map(({ date = '', subject = '' }, index) => (
              <Button
                className={classes.card}
                onClick={() => handleModalOpen(emails[index])}
              >
                <div className={classes.cardContent}>
                  <Typography className={classes.text}>
                    {subject.length < 60
                      ? subject
                      : `${typeof subject === 'string' ? subject.substr(0, 65) : ''}...`}
                  </Typography>
                  <Typography className={classes.subText}>
                    {date}
                  </Typography>
                </div>
              </Button>
            ))}
          {!isShow && emails.length === 0 && (
            <div className={classes.emptyWrp}>
              <InboxIcon className={classes.emptyIcon} />
              <Typography className={classes.emptyText}>
                0 results
              </Typography>
              <Typography className={classes.subText}>
                Please select a mailbox in order to initiate search.
              </Typography>
            </div>
          )}
        </Hidden>

        {isAdmin && (
          <EmailParserModal
            handleParseAttachments={handleParseAttachments}
            handleParseRelevantLinks={handleParseRelevantLinks}
            handleParseAll={handleParseAll}
            parseEmail={emailParser}
            isOpen={isOpen}
            filesExists={filesExists}
            handleClose={handleModalClose}
            handleMailRefresh={handleMailRefresh}
            handleMailReparse={handleMailReparse}
            handleSaveToDatabase={handleSaveToDatabase}
            handleAddToDatabase={handleAddToDatabase}
            isLoading={isLoading}
          />
        )}
      </div>
    </Container>
  );
}

ParserTable.propTypes = propTypes;
ParserTable.defaultProps = defaultProps;

export default withStyles(styles)(ParserTable);
