import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import superagent from 'superagent';
import { showNotification } from '../redux/modules/app/appActions';
import ParseHeader from '../components/ParserHeader';
import ParserTable from '../components/ParserTable';

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const isAdmin = true;

/*export const houses = [
  { name: 'albert', value: 1 },
  { name: 'argus', value: 2 },
  { name: 'ascendiant', value: 3 },
  { name: 'atlantic', value: 4 },
  { name: 'avondale', value: 5 },
  { name: 'baird', value: 6 },
  { name: 'barclays', value: 7 },
  { name: 'barrington', value: 8 },
  { name: 'bbt', value: 9 },
  { name: 'benchmark', value: 10 },
  { name: 'berenberg', value: 11 },
  { name: 'bgc', value: 12 },
  { name: 'bmo', value: 13 },
  { name: 'bnp', value: 14 },
  { name: 'boenning', value: 15 },
  { name: 'brean', value: 16 },
  { name: 'briley', value: 17 },
  { name: 'btig', value: 18 },
  { name: 'bucky', value: 19 },
  { name: 'canaccord', value: 20 },
  { name: 'cantor', value: 21 },
  { name: 'capitalone', value: 22 },
  { name: 'chardan', value: 23 },
  { name: 'cibc', value: 24 },
  { name: 'citi', value: 25 },
  { name: 'clarkson', value: 26 },
  { name: 'clsa', value: 27 },
  { name: 'colliers', value: 28 },
  { name: 'compass', value: 29 },
  { name: 'cowen', value: 30 },
  { name: 'craighallum', value: 31 },
  { name: 'crt', value: 32 },
  { name: 'csfb', value: 33 },
  { name: 'dada', value: 34 },
  { name: 'daiwa', value: 35 },
  { name: 'desjardins', value: 36 },
  { name: 'deutsche', value: 37 },
  { name: 'dougherty', value: 38 },
  { name: 'drexel', value: 39 },
  { name: 'evercore', value: 40 },
  { name: 'fbn', value: 41 },
  { name: 'fbr', value: 42 },
  { name: 'feltl', value: 43 },
  { name: 'firstanalysis', value: 44 },
  { name: 'gabelli', value: 45 },
  { name: 'ghs', value: 46 },
  { name: 'gilford', value: 47 },
  { name: 'gleacher', value: 48 },
  { name: 'goldman', value: 49 },
  { name: 'guggenheim', value: 50 },
  { name: 'hcw', value: 51 },
  { name: 'hilliard', value: 52 },
  { name: 'hsbc', value: 53 },
  { name: 'hudsonsquare', value: 54 },
  { name: 'imperial', value: 55 },
  { name: 'janney', value: 56 },
  { name: 'jefferies', value: 57 },
  { name: 'jmp', value: 58 },
  { name: 'jpm', value: 59 },
  { name: 'kaufman', value: 60 },
  { name: 'kbw', value: 61 },
  { name: 'keybanc', value: 62 },
  { name: 'klr', value: 63 },
  { name: 'ladenburg', value: 64 },
  { name: 'lakestreet', value: 65 },
  { name: 'leerink', value: 66 },
  { name: 'longbow', value: 67 },
  { name: 'loop', value: 68 },
  { name: 'macquarie', value: 69 },
  { name: 'maxim', value: 70 },
  { name: 'merriman', value: 71 },
  { name: 'miller', value: 72 },
  { name: 'mizuho', value: 73 },
  { name: 'mkm', value: 74 },
  { name: 'mlco', value: 75 },
  { name: 'mlv', value: 76 },
  { name: 'morganjoseph', value: 77 },
  { name: 'moffett', value: 78 },
  { name: 'msco', value: 79 },
  { name: 'needham', value: 80 },
  { name: 'noble', value: 81 },
  { name: 'nomura', value: 82 },
  { name: 'northcoast', value: 83 },
  { name: 'northland', value: 84 },
  { name: 'opco', value: 85 },
  { name: 'paccrest', value: 86 },
  { name: 'piper', value: 87 },
  { name: 'pivotal', value: 88 },
  { name: 'pritchard', value: 89 },
  { name: 'raja', value: 90 },
  { name: 'rbc', value: 91 },
  { name: 'rodman', value: 92 },
  { name: 'rosenblatt', value: 93 },
  { name: 'roth', value: 94 },
  { name: 'sandler', value: 95 },
  { name: 'scotia', value: 96 },
  { name: 'sidoti', value: 97 },
  { name: 'standard', value: 98 },
  { name: 'stephens', value: 99 },
  { name: 'sterne', value: 100 },
  { name: 'stifel', value: 101 },
  { name: 'summerstreet', value: 102 },
  { name: 'summit', value: 103 },
  { name: 'suntrust', value: 104 },
  { name: 'susquehanna', value: 105 },
  { name: 'telsey', value: 106 },
  { name: 'topeka', value: 107 },
  { name: 'ubs', value: 108 },
  { name: 'verity', value: 109 },
  { name: 'wedbush', value: 110 },
  { name: 'wells', value: 111 },
  { name: 'williamblair', value: 112 },
  { name: 'wunderlich', value: 113 },
  { name: 'wolfe', value: 114 },
  { name: 'bernstein', value: 115 },
  { name: 'jrco', value: 116 },
  { name: 'td', value: 117 },
  { name: 'seaport', value: 118 },
  { name: 'otr', value: 144 },
  { name: 'cleveland', value: 140 },
  { name: 'edgewater', value: 130 },
];*/

const PageParser = (props) => {
  const { classes } = props;
  const [output, setOutput] = useState('');
  const [mailboxes, setMailboxes] = useState([
    { name: 'ah/INBOX', value: 3 },
    { name: 'paks/INBOX', value: 9 },
    { name: 'paks/Sent', value: 11 },
    { name: 'paks/FAILEDMAILS', value: 124 },
    { name: 'paks/IGNOREDMAILS', value: 125 },
    { name: 'paks/TESTING', value: 126 },
  ]);
  const [senders, setSenders] = useState([
    { name: 'darren', value: 1 },
    { name: 'neil', value: 2 },
    { name: 'streetre', value: 3 },
    { name: 'david', value: 4 },
    { name: 'marek', value: 5 },
    { name: 'alari', value: 6 },
    { name: 'olavi', value: 7 },
    { name: 'wedbush', value: 8 },
    { name: 'keybanc', value: 9 },
    { name: 'kohlheim', value: 10 },
    { name: 'wegwerf', value: 11 },
    { name: 'docbase', value: 12 },
    { name: 'zacks', value: 13 },
    { name: 'roth', value: 14 },
    { name: 'marat', value: 15 },
    { name: 'hcw', value: 16 },
    { name: 'templeton', value: 17 },
    { name: 'tyler', value: 18 },
    { name: 'undefined', value: 19 },
  ]);
  const counts = [1, 5, 10, 20, 50, 100];
  const [isShow, setIsShow] = useState(false);
  const [value, setValue] = useState({
    mailbox: -1,
    sender: -1,
    house: -1,
    count: 1,
    mode: 1,
  });
  const [houses, setHouses] = useState([]);
  const dispatch = useDispatch();
  const ShowNotification = (action) =>
    dispatch(showNotification(action));

  const isLoggedIn = localStorage.getItem('isLoggedIn');
  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  useEffect(() => {
    superagent
      .get('/xof/parser')
      .then(() => console.info('visited'))
      .catch(() => console.error('something went wrong'));
  }, []);

  // useEffect(() => {
  //   superagent
  //     .get('/parse-emails/folders')
  //     .then((res) => {
  //       setMailboxes(res.body);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       ShowNotification({
  //         title: 'Error',
  //         message: err.response ? err.response.text : err.message,
  //       });
  //     });
  //   superagent
  //     .get('/parse-emails/houses')
  //     .then((res) => {
  //       setHouses(res.body);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       ShowNotification({
  //         title: 'Error',
  //         message: err.response ? err.response.text : err.message,
  //       });
  //     });
  //   superagent
  //     .get('/parse-emails/senders')
  //     .then((res) => {
  //       setSenders(res.body);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       ShowNotification({
  //         title: 'Error',
  //         message: err.response ? err.response.text : err.message,
  //       });
  //     });
  // }, []);

  const getHouses = () => {
    superagent
      .get('/xof/parser/json?get_research_houses=1')
      .then((res) => {
        const housesJSON = JSON.parse(res.text);
        const housesArray = [];
        Object.keys(housesJSON).map((idx) => {
          housesArray.push({name: housesJSON[parseInt(idx, 10)], value: parseInt(idx, 10)});
        });
        setHouses([...housesArray]);
      })
      .catch((err) => console.error(err));
  };
  
  useEffect(() => {
    getHouses();
  }, []);

  const handleParseAttachments = (folderName, emailId) => {
    superagent
      .post('/parse-emails/attachments/parse')
      .send({ folderName, emailId })
      .then((_) => {
        ShowNotification({
          title: 'Success',
          message:
            'All attachments were parsed successfully! Please check /data/test to confirm',
        });
      })
      .catch((err) => {
        console.error(err);
        ShowNotification({
          title: 'Error',
          message: err.response ? err.response.text : err.message,
        });
      });
  };

  const handleParseRelevantLinks = (folderName, emailId) => {
    superagent
      .post('/parse-emails/relevant-links/parse')
      .send({ folderName, emailId })
      .then((_) => {
        ShowNotification({
          title: 'Success',
          message:
            'All attachments were parsed successfully! Please check /data/test to confirm',
        });
      })
      .catch((err) => {
        console.error(err);
        ShowNotification({
          title: 'Error',
          message: err.response ? err.response.text : err.message,
        });
      });
  };

  const handleParseAll = (folderName, emailId) => {
    superagent
      .post('/parse-emails/all/parse')
      .send({ folderName, emailId })
      .then((_) => {
        ShowNotification({
          title: 'Success',
          message:
            'All attachments were parsed successfully! Please check /data/test to confirm',
        });
      })
      .catch((err) => {
        console.error(err);
        ShowNotification({
          title: 'Error',
          message: err.response ? err.response.text : err.message,
        });
      });
  };

  const renderMailboxOptions = () => {
    return mailboxes.map((m, i) => {
      return (
        <option key={m} value={m}>
          {m}
        </option>
      );
    });
  };

  const renderSendersOptions = () => {
    return senders.map((s) => {
      return (
        <option key={s} value={s}>
          {s}
        </option>
      );
    });
  };

  const renderHouseOptions = () => {
    return houses.map((h) => {
      return (
        <option key={h} value={h}>
          {h}
        </option>
      );
    });
  };

  const handleSearch = () => {
    setOutput('');
    setIsShow(true);
    if (+value.mailbox === -1) {
      ShowNotification({
        title: 'Error',
        message: 'Please select a mailbox in order to search',
      });
      setIsShow(false);
    } else {
      superagent
        .get(
          `/xof/parser/json?mailbox=${value.mailbox}&sender=${value.sender}&house=${value.house}&number_to_show=${value.count}&process_mode=${value.mode}&filterscount=0&groupscount=0&pagenum=0&pagesize=50&recordstartindex=0&recordendindex=50&featureClass=P&style=full&maxRows=50&_=1626481059982`
        )
        .withCredentials()
        .set('Content-Type', 'application/json')
        .then((res) => {
          console.log(res, 'search response');
          setOutput(res.text.substring(res.text.indexOf('[')));
          setIsShow(false);
        })
        .catch((err) => {
          let message = err.response
            ? err.response.text
            : err.message;
          if (err.status === 404) {
            message = 'Not Found';
          }
          console.error(err);
          ShowNotification({
            title: 'Error',
            message,
          });
          setIsShow(false);
        });
    }
  };

  const handleChange = (name) => (event) => {
    setValue({ ...value, [name]: event.target.value });
  };

  // const loadMore = () => {
  //   superagent
  //     .post('/parse-emails')
  //     .set('Content-Type', 'application/json')
  //     .send(value)
  //     .then((res) => {
  //       setOutput(
  //         output.concat(JSON.stringify(res.body, null, '\t'))
  //       );
  //     })
  //     .catch((err) => {
  //       let message = err.response ? err.response.text : err.message;
  //       if (err.status === 404) {
  //         message = 'Not Found';
  //       }
  //       console.error(err);
  //       ShowNotification({
  //         title: 'Error',
  //         message,
  //       });
  //     });
  // };

  // const loadMoreRows = () => {
  //   loadMore();
  // };

  const actualMailboxes = ['ah/INBOX', 'paks/INBOX', 'paks/Sent', 'paks/FAILEDMAILS', 'paks/IGNOREDMAILS', 'paks/TESTING'];
  const filteredMailboxes = mailboxes.filter((mailbox) =>
    actualMailboxes.includes(mailbox.name)
  );

  return (
    <div className={classes.root}>
      <ParseHeader
        isAdmin={isAdmin}
        mailboxes={filteredMailboxes}
        senders={senders}
        houses={houses}
        counts={counts}
        handleSearch={handleSearch}
        value={value}
        handleChange={handleChange}
      />
      <ParserTable
        handleParseAttachments={handleParseAttachments}
        handleParseRelevantLinks={handleParseRelevantLinks}
        handleParseAll={handleParseAll}
        isAdmin={isAdmin}
        output={output}
        isShow={isShow}
        value={value}
      // loadMoreRows={loadMoreRows}
      />
    </div>
  );
};

PageParser.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(PageParser);
