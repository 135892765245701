import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from '../components/TableHistory';
import Header from '../components/HistoryHeader';
import superagent from 'superagent';

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const isAdmin = true;

const Status = (props) => {
  const { classes } = props;

  const [isShow, setIsShow] = useState(true);
  const [page, setPage] = useState(0);
  const [files, setFiles] = useState([]);
  const [mailNumber, setMailNumber] = useState(0);
  const [filterHouse, setFilterHouse] = useState('any');
  const [broker, setBroker] = useState(-1);
  const [houses, setHouses] = useState([]);

  const isLoggedIn = localStorage.getItem('isLoggedIn');
  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }
  
  const getHouses = () => {
    superagent
      .get('/xof/parser/json?get_research_houses=1')
      .then((res) => {
        const housesJSON = JSON.parse(res.text);
        const housesArray = [];
        Object.keys(housesJSON).map((idx) => {
          housesArray.push({name: housesJSON[parseInt(idx, 10)], value: parseInt(idx, 10)});
        });
        setHouses([...housesArray]);
      })
      .catch((err) => console.error(err));
  };
  
  const changePage = (page) => {
    setFiles([]);
    setPage(page);
    superagent
    .get(`/history/filter/data/${filterHouse}/${broker}/${page}`)
    .then((res) => {
      setFiles(res.body);
      setIsShow(false);
    })
    .catch((err) => {
      console.error(err);
    });
  }
  
   const getHousesAndLocations = (e) => {
     setFiles([]);
    superagent
      .get(`/history/${e}`)
      .then((res) => {
        setFiles(res.body);
        setIsShow(false);
      })
      .catch((err) => {
        console.error(err);
      });
    };
    
   const getCount = () => {
    superagent
      .get('/history/mail/count')
      .then((res) => {
        setMailNumber(res.body);
      })
      .catch((err) => {
        console.error(err);
      });
    };

    
  const initialLoad = () => {
    getHouses();
    getHousesAndLocations(0);
    getCount();
  }
  
  useEffect(() => {
    initialLoad();
  }, [])
  
  const handleFilter = () => {
    setPage(0);
    setFiles([]);
    if(filterHouse === 'any' && broker === -1){
      return initialLoad();
    }
    superagent
      .get(`/history/filter/data/${filterHouse}/${broker}/0`)
      .then((res) => {
        setFiles(res.body);
        setIsShow(false);
      })
      .catch((err) => {
        console.error(err);
      });
     superagent
      .get(`/history/filter/${filterHouse}/${broker}/count`)
      .then((res) => {
        setMailNumber(res.body);
      })
      .catch((err) => {
        console.error(err);
      }); 
  }
  

  return (
    <div className={classes.root}>
      <Header
        isAdmin={isAdmin}
        filterHouse={filterHouse}
        setFilterHouse={setFilterHouse}
        setFiles={setFiles}
        broker={broker}
        setBroker={setBroker}
        handleFilter={handleFilter}
        houses={houses}
      />
      <Table
        files={files}
        isShow={isShow}
        page={page}
        setPage={changePage}
        mailNumber={mailNumber}
      />
    </div>
  );
};

Status.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Status);
