import React from 'react';
import { SvgIcon } from '@material-ui/core';

const RegionIcon = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 14 14" {...props}>
      <path d="M7 0a7 7 0 107 7 7.008 7.008 0 00-7-7zm5.552 6.3h-1.935a10.269 10.269 0 00-1.254-4.37 5.609 5.609 0 013.189 4.37zM7.371 1.419A9.094 9.094 0 019.21 6.3H4.921a8.928 8.928 0 011.716-4.882c.12-.007.241-.018.363-.018s.248.011.371.019zm-2.689.49A10.6 10.6 0 003.521 6.3H1.448a5.609 5.609 0 013.234-4.391zM1.448 7.7H3.53a10.968 10.968 0 001.089 4.361A5.607 5.607 0 011.448 7.7zm5.167 4.881A8.382 8.382 0 014.929 7.7h4.279a8.543 8.543 0 01-1.822 4.88c-.127.008-.255.02-.386.02s-.257-.011-.385-.019zm2.808-.54A10.737 10.737 0 0010.606 7.7h1.945a5.6 5.6 0 01-3.128 4.341z" />
    </SvgIcon>
  );
};

export default RegionIcon;
