import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CheckboxIcon = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <g
        data-name="Checkbox"
        fill="rgba(255,255,255,0)"
        stroke="#cbd5e0"
        strokeWidth="1.4"
      >
        <rect width="16" height="16" rx="4" stroke="none" />
        <rect
          x=".7"
          y=".7"
          width="14.6"
          height="14.6"
          rx="3.3"
          fill="none"
        />
      </g>
    </SvgIcon>
  );
};

export default CheckboxIcon;
