import React from 'react';
import { SvgIcon } from '@material-ui/core';

const HouseIcon = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 14 14" {...props}>
      <path d="M.7 7.7h.7v4.9A1.4 1.4 0 002.8 14h8.4a1.4 1.4 0 001.4-1.4V7.7h.7a.7.7 0 00.495-1.2L7.495.2a.7.7 0 00-.99 0l-6.3 6.3A.7.7 0 00.7 7.7zm4.9 4.9V9.1h2.8v3.5zM7 1.69l4.2 4.2v6.71H9.8V9.1a1.4 1.4 0 00-1.4-1.4H5.6a1.4 1.4 0 00-1.4 1.4v3.5H2.8V5.89z" />
    </SvgIcon>
  );
};

export default HouseIcon;
