import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Container,
  withStyles,
  Typography,
  Hidden,
  Button,
  Link,
} from '@material-ui/core';
import Pagination from './Pagination';
import Loader from '../uikits/Loader';

const propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  files: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isShow: PropTypes.bool,
};

const defaultProps = {
  files: [],
  isShow: false,
};

const styles = (theme) => ({
  wrp: {
    height: 'calc(100% - 19.75rem)',
    marginBottom: '1.28rem',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      overflowY: 'auto',
      marginBottom: '1rem',
    },
  },
  container: {
    height: '100%',
  },
  tableWrp: {
    height: 'calc(100% - 1.28rem)',
    overflow: 'auto',
  },
  tableInfo: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  table: {
    width: '100%',
    borderBottom: '0.08rem solid #E2E8F0',
    borderRadius: '0.88rem',
    borderSpacing: 0,
  },
  tableEmpty: {
    width: '100%',
    height: '100%',
    borderBottom: '0.08rem solid #E2E8F0',
    borderRadius: '0.88rem',
    borderSpacing: 0,
  },
  tr: {
    '&:nth-child(even)': {
      backgroundColor: '#FAFBFC',
    },
    '&:last-child': {
      '& td:first-child': {
        borderBottomLeftRadius: '0.88rem',
      },
      '& td:last-child': {
        borderBottomRightRadius: '0.88rem',
      },
    },
  },
  th: {
    padding: '0.875rem',
    fontSize: '1.125rem',
    fontWeight: '500',
    color: '#748297',
    textTransform: 'uppercase',
    backgroundColor: '#FAFBFC',
    borderBottom: '0.08rem solid #E2E8F0',
    borderTop: '0.08rem solid #E2E8F0',
    position: 'sticky',
    top: 0,
    zIndex: 9,
    '&:first-child': {
      borderLeft: '0.08rem solid #E2E8F0',
      borderTopLeftRadius: '0.88rem',
      '&:before': {
        background: '#fff',
        content: 'close-quote',
        height: '0.8rem',
        width: '0.08rem',
        position: 'absolute',
        display: 'block',
        left: '-0.08rem',
        top: '-0.08rem',
      },
    },
    '&:last-child': {
      borderRight: '0.08rem solid #E2E8F0',
      borderTopRightRadius: '0.88rem',
      '&:before': {
        background: '#fff',
        content: 'close-quote',
        height: '0.8rem',
        width: '0.08rem',
        position: 'absolute',
        display: 'block',
        right: '-0.08rem',
        top: '-0.08rem',
      },
    },
  },
  td: {
    padding: '0.16rem 1.12rem',
    color: '#4A5568',
    fontSize: '1.125rem',
    whiteSpace: 'nowrap',
    '&:first-child': {
      borderLeft: '0.08rem solid #E2E8F0',
    },
    '&:last-child': {
      borderRight: '0.08rem solid #E2E8F0',
    },
  },
  link: {
    textDecoration: 'none',
    color: '#3179F5',
  },
  tableHead: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1.28rem',
    marginBottom: '1.28rem',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
      marginTop: '1rem',
    },
  },
  info: {
    color: '#748297',
    fontSize: '1rem',
  },
  dark: {
    color: '#303E4E',
  },
  paginationSearch: {
    display: 'flex',
  },
  card: {
    width: '100%',
    maxHeight: '4rem',
    justifyContent: 'start',
    backgroundColor: '#FFFFFF',
    border: '0.08rem solid #E2E8F0',
    marginBottom: '1rem',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  subText: {
    color: '#748297',
  },
  loaderWrp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },
});

function Table(props) {
  const {
    classes,
    isShow,
    files,
    page,
    setPage,
    filesNumber,
  } = props;
  const rowsPerPage = 50;

  const tableClassName = cn(classes.table, {
    [classes.tableEmpty]: isShow,
  });

  const researchFileDate = (file_create_time) => {
    return `${(new Date(file_create_time).getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${new Date(file_create_time)
            .getDate()
            .toString()
            .padStart(2, "0")}-${new Date(file_create_time)
            .getFullYear()
            .toString()
            .padStart(4, "0")} ${new Date(file_create_time)
            .getHours()
            .toString()
            .padStart(2, "0")}:${new Date(file_create_time)
            .getMinutes()
            .toString()
            .padStart(2, "0")}:${new Date(file_create_time)
            .getSeconds()
            .toString()
            .padStart(2, "0")}`
  };

  return (
    <>
      <Container>
        <div className={classes.tableHead}>
          <div className={classes.paginationSearch}>
            <Pagination
              currentPage={page}
              changePage={setPage}
              itemsCount={filesNumber}
              itemsPerPage={rowsPerPage}
            />
          </div>
          <div className={classes.tableInfo}>
            <Typography className={classes.info}>
              Showing <span className={classes.dark}>1</span> to{' '}
              <span className={classes.dark}>50</span> of{' '}
              <span className={classes.dark}>{filesNumber}</span>{' '}
              files
              <br />
              Execution time:{' '}
              <span className={classes.dark}>9ms</span>
            </Typography>
          </div>
        </div>
      </Container>
      <div className={classes.wrp}>
        <Container className={classes.container}>
          <Hidden smDown>
            <div className={classes.tableWrp}>
              <table className={tableClassName}>
                <thead>
                  <tr>
                    <th className={classes.th} align="left">
                      TIME DOWNLOADED
                    </th>
                    <th className={classes.th} align="left">
                      HOUSE
                    </th>
                    <th className={classes.th} align="left">
                      COMPANY
                    </th>
                    <th className={classes.th} align="left">
                      TICKER
                    </th>
                    <th className={classes.th} align="left">
                      HEADLINE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isShow ? (
                    <tr className={classes.tr}>
                      <td
                        colSpan={7}
                        className={classes.td}
                        align="left"
                      >
                        <div className={classes.loaderWrp}>
                          <Loader className={classes.loader} />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    files.map((data) => (
                      <tr
                        className={classes.tr}
                        style={{
                          opacity: isShow ? 0 : 1,
                          transform: isShow
                            ? 'translateY(0.8rem)'
                            : 'translateY(0rem)',
                          transition:
                            '0.8s transform ease, 0.8s opacity ease',
                        }}
                        key={data.file_id}
                      >
                        <td className={classes.td} align="left">
                          {data.file_create_time
                            ? researchFileDate(data.file_create_time)
                            : ''}
                        </td>
                        <td className={classes.td} align="left">
                          {data.research_house?.housename_full}
                        </td>
                        <td className={classes.td} align="left">
                          {data.company}
                        </td>
                        <td className={classes.td} align="left">
                          {data.main_ticker}
                        </td>
                        <td className={classes.td} align="left">
                          <Link
                            href={`/research/files/${data.filenamehash}`}
                            className={classes.link}
                            target="_blank"
                          >
                            {data.headline.length < 60
                              ? data.headline
                              : `${data.headline.substr(0, 65)}...`}
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </Hidden>
          <Hidden mdUp>
            {isShow ? (
              <div className={classes.loaderWrp}>
                <Loader className={classes.loader} />
              </div>
            ) : (
              files.map((data) => (
                <Button disableRipple className={classes.card}>
                  <div className={classes.cardContent}>
                    <Link href="/" className={classes.link}>
                      {data.headline}
                    </Link>
                    <Typography className={classes.subText}>
                      {data.date_from_file}
                    </Typography>
                  </div>
                </Button>
              ))
            )}
          </Hidden>
        </Container>
      </div>
    </>
  );
}

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default withStyles(styles)(Table);
