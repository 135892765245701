import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import superagent from 'superagent';
import { showNotification } from '../redux/modules/app/appActions';
import Table from '../components/Table';
import Header from '../components/Header';

const propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const isAdmin = true;

function PageHome(props) {
  const { classes } = props;
  const [houses, setHouses] = useState([]);
  const [locations, setLocations] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesNumber, setFilesNumber] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [page, setPage] = useState(0);
  const [value, setValue] = useState({
    houses: [],
    locations: [],
  });
  const handleChangePage = (newPage) => {
    setIsShow(true);
    setPage(newPage);
    const house_ids = value.houses.map((house) => house.house_id);
    const location_ids = value.locations.map(
      (location) => location.location_id
    );
    getFiles(newPage, house_ids, location_ids);
  };

  const dispatch = useDispatch();
  const ShowNotification = (action) =>
    dispatch(showNotification(action));

  const isLoggedIn = localStorage.getItem('isLoggedIn');
  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }
  const getHousesAndLocations = () => {
    superagent
      .get('/research/all')
      .then((res) => {
        try {
          const {
            houses: dataHouses,
            locations: dataLocations,
          } = res.body;
          setHouses(
            Array.isArray(dataHouses)
              ? dataHouses.sort((a, b) =>
                  a.housename_full.localeCompare(b.housename_full)
                )
              : []
          );
          setLocations(
            Array.isArray(dataLocations)
              ? dataLocations.sort((a, b) =>
                  a.location_name.localeCompare(b.location_name)
                )
              : []
          );
          setValue({
            ...value,
            locations: Array.isArray(dataLocations)
              ? dataLocations.filter(
                  (l) =>
                    l.location_name === 'Unknown' ||
                    l.location_name === 'North America' ||
                    l.location_name === 'Global'
                )
              : [],
          });
        } catch (err) {
          console.error(err);
          setHouses([]);
          setLocations([]);
        }
        console.log('get houses and locations', res.body);
      })
      .catch((err) => {
        console.error(err);
        ShowNotification({
          title: 'Error',
          message: err.response ? err.response.text : err.message,
        });
      });
  };

  const getFiles = (page = 0, house_ids = [], location_ids = []) => {
    superagent
      .post('/research/files')
      .send({
        house_ids,
        location_ids,
        page,
      })
      .then((res) => {
        const dataFiles = res.body;
        setFiles(Array.isArray(dataFiles) ? dataFiles : []);
        setIsShow(false);
        console.log('get files with search', res.body);
      })
      .catch((err) => {
        let message = err.response ? err.response.text : err.message;
        if (err.status === 404) {
          message = 'Not Found';
        }
        console.error(err);
        ShowNotification({
          title: 'Error',
          message,
        });
        setIsShow(false);
      });
  };

  const getFilesNumber = (house_ids = [], location_ids = []) => {
    superagent
      .post('/research/files-number')
      .send({
        house_ids,
        location_ids,
      })
      .then((res) => {
        const filesNumber = res.body;
        setFilesNumber(filesNumber || 0);
      })
      .catch((err) => {
        let message = err.response ? err.response.text : err.message;
        if (err.status === 404) {
          message = 'Not Found';
        }
        console.error(err);
        ShowNotification({
          title: 'Error',
          message,
        });
      });
  };

  useEffect(() => {
    getHousesAndLocations();
    getFiles();
    getFilesNumber();
  }, []);

  const handleSearch = () => {
    setIsShow(true);
    const house_ids = value.houses.map((house) => house.house_id);
    const location_ids = value.locations.map(
      (location) => location.location_id
    );
    getFiles(page, house_ids, location_ids);
    getFilesNumber(house_ids, location_ids);
  };

  const handleChange = (name) => (event, options) => {
    setValue({ ...value, [name]: options });
  };

  return (
    <div className={classes.root}>
      <Header
        isAdmin={isAdmin}
        value={value}
        houses={houses}
        locations={locations}
        handleChangeOption={handleChange}
        handleSearch={handleSearch}
      />
      <Table
        files={files}
        isShow={isShow}
        page={page}
        setPage={handleChangePage}
        filesNumber={filesNumber}
      />
    </div>
  );
}

PageHome.propTypes = propTypes;
export default withStyles(styles)(PageHome);
