import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import rootReducer from '../reducers';
import config from '../../config';
import getBaseMiddlewares from './baseMiddlewares';
import { loadState } from './localStorage';

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const initialState = loadState();

const configureStore = () => {
  const middlewares = getBaseMiddlewares();
  if (!config.isProd && !config.isDisableReduxLogger) {
    middlewares.push(logger);
  }
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  return createStore(rootReducer, {}, enhancer, initialState);
};

export default configureStore;
