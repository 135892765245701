import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Snackbar,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { hideNotification } from '../redux/modules/app/appActions';
import { CancelIcon, InfoIcon } from '../icons';

const propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const styles = (theme) => ({
  notification: {
    transform: 'translateY(40rem)',
    opacity: 0,
    transition: '0.6s all ease',
    width: '29rem',
    [theme.breakpoints.down('sm')]: {
      width: '34.4rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 2.4rem)',
    },
  },
  notificationOpened: {
    transform: 'translateY(0rem)',
    opacity: 1,
  },
  snackbar: {
    backgroundColor: '#fff',
    width: '100%',
    padding: '1rem',
    alignItems: 'center',
    boxShadow: 'none',
    border: '0.08rem solid #E2E8F0',
    borderRadius: '1.13rem',
    flexWrap: 'inherit',
    [theme.breakpoints.down('xs')]: {
      minHeight: '3.5rem',
      padding: '0.5rem 0.875rem;',
    },
  },
  contentMessage: {
    maxWidth: '100%',
    padding: '0',
  },
  action: {
    margin: '0 0 0 auto',
    padding: 0,
  },
  avatar: {
    width: '3.5rem',
    height: '3.5rem',
    marginRight: '1rem',
    [theme.breakpoints.down('xs')]: {
      width: '2.375rem',
      height: '2.375rem',
    },
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: '#4A5568',
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: '0.25rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0.2rem',
    },
  },
  description: {
    color: '#748297',
    fontSize: '1rem',
  },
  close: {
    color: '#A0AEC0',
    backgroundColor: 'transparent',
    width: '2.5rem',
    height: '2.5rem',
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    '&:hover': {
      backgroundColor: '#F7FAFC',
    },
    '&:active': {
      backgroundColor: '#F7FAFC',
    },
  },
  closeIcon: {
    fontSize: '1rem',
  },
  infoIcon: {
    color: '#A0AEC0',
    position: 'absolute',
    top: '1rem',
    left: '1rem',
  },
});

const Notification = (props) => {
  const { classes, className } = props;
  const [isShow, setShow] = useState(false);

  const dispatch = useDispatch();
  const isOpen = useSelector(
    (state) => state.app.notification.isOpened
  );
  const title = useSelector((state) => state.app.notification.title);
  const message = useSelector(
    (state) => state.app.notification.message
  );
  const HideNotification = () => dispatch(hideNotification());

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  const notificationClassName = cn(classes.notification, {
    [classes.notificationOpened]: isShow,
  });

  const handleClose = () => {
    HideNotification();
  };

  return (
    <>
      <Snackbar
        className={cn(notificationClassName, className)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: {
            root: classes.snackbar,
            message: classes.contentMessage,
            action: classes.action,
          },
        }}
        message={
          <div id="message-id" className={classes.message}>
            <InfoIcon className={classes.infoIcon} />
            <Box component="div" px="1.92rem">
              <Typography type="subheading" className={classes.title}>
                {title}
              </Typography>
              <Typography className={classes.description}>
                {message}
              </Typography>
            </Box>
          </div>
        }
        action={[
          <IconButton onClick={handleClose} className={classes.close}>
            <CancelIcon className={classes.closeIcon} />
          </IconButton>,
        ]}
      />
    </>
  );
};

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

export default withStyles(styles)(Notification);
