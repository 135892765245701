import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles, CircularProgress } from '@material-ui/core';
import { CircleIcon } from '../icons';

const propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const styles = {
  container: {
    position: 'relative',
  },
  bottom: {
    color: '#3B83FC',
    position: 'absolute',
    left: 0,
  },
  top: {
    width: '3.84rem',
    height: '3.84rem',
    color: 'transparent',
    stroke: '#D7E6FE',
  },
};

const Loader = (props) => {
  const { classes, className } = props;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 20
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={cn(classes.container, className)}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={48}
        thickness={4}
        value={progress}
      />
      <CircleIcon className={classes.top} />
    </div>
  );
};

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default withStyles(styles)(Loader);
