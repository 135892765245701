import React from 'react';
import { SvgIcon } from '@material-ui/core';

const InboxIcon = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M19.056 2h-14a3.003 3.003 0 00-3 3v14a3.003 3.003 0 003 3h14a3.003 3.003 0 003-3V5a3.003 3.003 0 00-3-3zm-14 2h14a1.001 1.001 0 011 1v8H17.59a1.997 1.997 0 00-1.664.89L14.52 16H9.59l-1.406-2.11A1.997 1.997 0 006.52 13H4.056V5a1.001 1.001 0 011-1zm14 16h-14a1.001 1.001 0 01-1-1v-4H6.52l1.406 2.11A1.997 1.997 0 009.59 18h4.93a1.997 1.997 0 001.664-.89L17.59 15h2.465v4a1.001 1.001 0 01-1 1z" />
    </SvgIcon>
  );
};

export default InboxIcon;
