import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
} from '@material-ui/core';

const propTypes = {
  isRelevant: PropTypes.bool.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  link: PropTypes.instanceOf(Object).isRequired,
};

const styles = (theme) => ({
  link: {
    marginBottom: '1.5rem',
  },
})

function Link(props) {
  const {
    classes,
    isRelevant,
    link,
  } = props;

  return (
    <>
      {
        (link.href && link.href !== '') &&
        <>
          <b>OHREF:</b>
          <a href={link.href} className={classes.link}>
            {` `}
            {link.href}
          </a>
          <br />
        </>
      }
      <b>AHREF:</b>
      {` `}
      {link.originalHTML}
      <br />
      {isRelevant && (
        <>
          <b>FHREF:</b>
          <a
            href={link.formattedHref}
            className={classes.link}
          >
            {` `}
            {link.formattedHref}
          </a>
          <br />
          <b>Pattern:</b>
          {` `}
          {link.pattern}
        </>
      )}
    </>
  );
}

Link.propTypes = propTypes;
export default withStyles(styles)(Link);
