import React, { useState, useEffect } from 'react';
import superagent from 'superagent';
import { withStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Table from '../components/TableHouse';
import Header from '../components/HouseHeader';

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const isAdmin = true;

const House = (props) => {
  const { classes } = props;

  const [isShow, setIsShow] = useState(true);
  const [data, setData] = useState([]);
  const [houses, setHouses] = useState([]);

  const isLoggedIn = localStorage.getItem('isLoggedIn');
  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  const getHousesAndLocations = () => {
    superagent
      .get('/house/all')
      .then((res) => {
        const final = res.body.files[0].map((e) => {
          const housename = res.body.houses.find(
            (el) => el.house_id === e.house_id
          );
          if (housename) {
            return {
              ...e,
              housename: housename.housename,
            };
          }
          return e;
        });
        console.info(final);
        setData(
          final.sort((a, b) => {
            if (a.file_create_time > b.file_create_time) {
              return -1;
            }
            if (a.file_create_time < b.file_create_time) {
              return 1;
            }
            return 0;
          })
        );
        setHouses(res.body.houses);
        setIsShow(false);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getHousesAndLocations();
  }, []);

  const getFilteredHouse = (e) => {
    if (e === 'any') {
      return getHousesAndLocations();
    }
    return superagent
      .get(`/house/all/${e}`)
      .then((res) => {
        setData(res.body);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className={classes.root}>
      <Header isAdmin={isAdmin} getHouse={getFilteredHouse} />
      <Table files={data} isShow={isShow} houses={houses} />
    </div>
  );
};

House.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(House);
