import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import App from './App';
import store from './redux/store';
import theme from './theme/theme';
import history from './sqhistory';
import './styles/styles.scss';

document.body.height = '100%';
const mainElement = document.createElement('div');
mainElement.setAttribute('id', 'root');
document.body.appendChild(mainElement);

render(
  <ThemeProvider theme={theme}>
    <Router history={history}>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </ThemeProvider>,
  mainElement
);
