import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Button,
  Hidden,
  Container,
  Select,
  MenuItem,
  withStyles,
  Typography,
} from '@material-ui/core';
import {
  MailBoxIcon,
  SenderIcon,
  InfoIcon,
  HouseIcon,
  DropDownIcon,
} from '../icons';
import ParserSwitch from '../uikits/ParserSwitch';
import SearchButton from '../uikits/SearchButton';

const propTypes = {
  isAdmin: PropTypes.bool,
  classes: PropTypes.instanceOf(Object).isRequired,
  mailboxes: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  senders: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  houses: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  counts: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.instanceOf(Object).isRequired,
  handleSearch: PropTypes.func,
  handleChange: PropTypes.func,
};

const defaultProps = {
  isAdmin: false,
  mailboxes: [],
  senders: [],
  houses: [],
  counts: [],
  handleSearch: () => {},
  handleChange: () => {},
};

const styles = (theme) => ({
  header: {
    paddingTop: '1.92rem',
    marginBottom: '0.64rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '1.2rem',
    },
  },
  container: {
    paddingLeft: '1.92rem',
    paddingRight: '1.92rem',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '1.2rem',
      paddingRight: '1.2rem',
    },
  },
  wrp: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  filterBlock: {
    display: 'flex',
    order: 1,
  },
  filterBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 'auto',
    minHeight: 'auto',
    color: '#4A5568',
    padding: 0,
  },
  mainBlock: {
    minWidth: '22.32rem',
    marginRight: '2rem',
    [theme.breakpoints.down('md')]: {
      minWidth: '20rem',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      display: 'flex',
      justifyContent: 'space-between',
      order: 1,
      marginRight: 0,
    },
  },
  countBlock: {
    minWidth: '18.24rem',
    marginRight: 0,
    '& $info': {
      minWidth: '7.84rem',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      order: 1,
      '& $info': {
        minWidth: 'auto',
      },
      '& $select': {
        marginBottom: 0,
      },
    },
  },
  block: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '48%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '49%',
    },
  },
  info: {
    minWidth: '4.8rem',
    display: 'flex',
    alignItems: 'center',
    color: '#4A5568',
    height: '3.2rem',
    whiteSpace: 'nowrap',
    marginRight: '1.6rem',
    '& svg': {
      color: '#748297',
      marginRight: '0.48rem',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '5.36rem',
      marginRight: '0.8rem',
    },
  },
  rightBlock: {
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      textAlign: 'end',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  right: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
      order: 0,
      marginBottom: '2.188rem',
    },
  },
  login: {
    display: 'flex',
    alignItems: 'baseline',
  },
  username: {
    color: '#303E4E',
    fontSize: '1.12rem',
    fontWeight: 600,
  },
  logoutBtn: {
    minWidth: '5rem',
    minHeight: '3.12rem',
    color: '#4A5568',
    backgroundColor: '#F7FAFC',
    border: '0.08rem solid #EDF2F7',
    marginLeft: '0.8rem',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  select: {
    height: '3.2rem',
    color: '#4A5568',
    marginBottom: '0.8rem',
  },
  outlined: {
    borderRadius: 0,
    border: 'none',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  filterText: {
    fontSize: '1.125rem',
    fontWeight: 600,
    color: '#4A5568',
  },
  icon: {
    fontSize: '1.5rem',
    right: '0.5rem',
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
  paper: {
    overflowY: 'hidden',
    boxShadow: 'none',
    marginTop: '0.8rem',
  },
  list: {
    width: 'auto',
    maxHeight: '25.6rem',
    overflowY: 'auto',
    padding: '0.4rem',
  },
  item: {
    paddingTop: '0.32rem',
    paddingBottom: '0.32rem',
  },
  searchBtn: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  line: {
    display: 'flex',
    height: '0.16rem',
    backgroundColor: '#EDF2F7',
    margin: '1rem 1.92rem 0 1.92rem',
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 1.2rem 0 1.2rem',
    },
  },
  lineHide: {
    display: 'none',
  },
});

const ParseHeader = (props) => {
  const {
    classes,
    isAdmin,
    mailboxes,
    senders,
    houses,
    counts,
    handleSearch,
    value,
    handleChange,
  } = props;

  const [showFilter, setShowFilter] = useState(true);

  const username = useSelector((state) => state.app.user.username);

  const history = useHistory();

  const handleFilterOpen = () => {
    setShowFilter((prevState) => !prevState);
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push('/login');
  };

  const iconClassName = cn(classes.icon, {
    [classes.iconOpen]: showFilter,
  });

  const lineClassName = cn(classes.line, {
    [classes.lineHide]: !showFilter,
  });
  
  console.log(mailboxes)

  return (
    <>
      <div className={classes.header}>
        <Container className={classes.container}>
          <div className={classes.wrp}>
            <Hidden mdUp>
              <div className={classes.filterBlock}>
                <Button
                  className={classes.filterBtn}
                  onClick={handleFilterOpen}
                >
                  <Typography className={classes.filterText}>
                    Filters
                  </Typography>
                  <DropDownIcon className={iconClassName} />
                </Button>
              </div>
            </Hidden>
            {showFilter && (
              <>
                <div className={classes.mainBlock}>
                  <div className={classes.block}>
                    <span className={classes.info}>
                      <MailBoxIcon />
                      <span>Mailbox</span>
                    </span>
                    <Select
                      id="mailboxes"
                      className={classes.select}
                      fullWidth
                      variant="outlined"
                      value={value.mailbox}
                      classes={{
                        iconOutlined: classes.icon,
                        outlined: classes.outlined,
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        classes: {
                          paper: classes.paper,
                          list: classes.list,
                        },
                      }}
                      onChange={handleChange('mailbox')}
                    >
                      <MenuItem
                        value={-1}
                        className={classes.item}
                        classes={{ selected: classes.selected }}
                      >
                        Choose a mailbox...
                      </MenuItem>
                      {mailboxes.sort().map((m) => (
                        <MenuItem
                          key={m.value}
                          value={m.value}
                          className={classes.item}
                          classes={{ selected: classes.selected }}
                        >
                          {m.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={classes.block}>
                    <span className={classes.info}>
                      <SenderIcon />
                      <span>Sender</span>
                    </span>
                    <Select
                      id="senders"
                      className={classes.select}
                      fullWidth
                      variant="outlined"
                      value={value.sender}
                      classes={{
                        iconOutlined: classes.icon,
                        outlined: classes.outlined,
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        classes: {
                          paper: classes.paper,
                          list: classes.list,
                        },
                      }}
                      onChange={handleChange('sender')}
                    >
                      <MenuItem
                        value={-1}
                        className={classes.item}
                        classes={{ selected: classes.selected }}
                      >
                        All senders
                      </MenuItem>
                      {senders.sort().map((s) => (
                        <MenuItem
                          key={s.value}
                          value={s.value}
                          className={classes.item}
                          classes={{ selected: classes.selected }}
                        >
                          {s.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className={classes.mainBlock}>
                  <div className={classes.block}>
                    <span className={classes.info}>
                      <HouseIcon />
                      <span>House</span>
                    </span>
                    <Select
                      id="houses"
                      className={classes.select}
                      fullWidth
                      variant="outlined"
                      value={value.house}
                      classes={{
                        iconOutlined: classes.icon,
                        outlined: classes.outlined,
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        classes: {
                          paper: classes.paper,
                          list: classes.list,
                        },
                      }}
                      onChange={handleChange('house')}
                    >
                      <MenuItem
                        value={-1}
                        className={classes.item}
                        classes={{ selected: classes.selected }}
                      >
                        No rules
                      </MenuItem>
                      {houses.sort((a,b) => {
  if (a.name < b.name)
    return -1;
  if (a.name > b.name)
    return 1;
  return 0;
}).map((h) => (
                        <MenuItem
                          key={h.value}
                          value={h.value}
                          className={classes.item}
                          classes={{ selected: classes.selected }}
                        >
                          {h.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={classes.block}>
                    <span className={classes.info}>
                      <InfoIcon className={classes.icon} />
                      <span>Mode</span>
                    </span>
                    <Select
                      id="mode"
                      className={classes.select}
                      fullWidth
                      variant="outlined"
                      value={value.mode}
                      classes={{
                        iconOutlined: classes.icon,
                        outlined: classes.outlined,
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        classes: {
                          paper: classes.paper,
                          list: classes.list,
                        },
                      }}
                      onChange={handleChange('mode')}
                    >
                      <MenuItem
                        value={2}
                        className={classes.item}
                        classes={{ selected: classes.selected }}
                      >
                        Parse
                      </MenuItem>
                      <MenuItem
                        value={1}
                        className={classes.item}
                        classes={{ selected: classes.selected }}
                      >
                        Debug
                      </MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.countBlock}>
                  <div className={classes.block}>
                    <span className={classes.info}>
                      <span>Number of emails</span>
                    </span>
                    <Select
                      id="count"
                      className={classes.select}
                      fullWidth
                      variant="outlined"
                      value={value.count}
                      classes={{
                        iconOutlined: classes.icon,
                        outlined: classes.outlined,
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        classes: {
                          paper: classes.paper,
                          list: classes.list,
                        },
                      }}
                      onChange={handleChange('count')}
                    >
                      {counts.map((c) => (
                        <MenuItem
                          key={c}
                          value={c}
                          className={classes.item}
                          classes={{ selected: classes.selected }}
                        >
                          {c}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={classes.block}>
                    <SearchButton
                      handleSearch={handleSearch}
                      className={classes.searchBtn}
                    />
                  </div>
                </div>
              </>
            )}
            <div className={classes.rightBlock}>
              <div className={classes.right}>
                {isAdmin && (
                  <ParserSwitch className={classes.switch} />
                )}

                <div className={classes.login}>
                  <span className={classes.username}>{username}</span>
                  <Button
                    color="primary"
                    className={classes.logoutBtn}
                    onClick={handleLogout}
                  >
                    Log out
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Hidden mdUp>
        <span className={lineClassName} />
      </Hidden>
    </>
  );
};

ParseHeader.propTypes = propTypes;
ParseHeader.defaultProps = defaultProps;

export default withStyles(styles)(ParseHeader);
