import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PageLogin from './pages/PageLogin';
import PageHome from './pages/PageHome';
import PageParser from './pages/PageParser';
import PageHistory from './pages/PageHistory';
import Notification from './uikits/Notifcation';
import PageHouse from './pages/PageHouse';
import PageProxy from './pages/PageProxy';

function Empty() {
  return <div />;
}

function App() {
  return (
    <BrowserRouter key="0">
      <Switch>
        <Route exact path="/research" component={PageHome} />
        <Route exact path="/login" component={PageLogin} />
        <Route exact path="/email" component={PageParser} />
        <Route exact path="/history" component={PageHistory} />
        <Route exact path="/house" component={PageHouse} />
        <Route exact path="/proxy" component={PageProxy} />
        <Route component={Empty} />
      </Switch>
      <Notification />
    </BrowserRouter>
  );
}

export default App;
