import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Button,
  Container,
  FormControlLabel,
  Checkbox,
  withStyles,
  TextField,
  Hidden,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ParserSwitch from '../uikits/ParserSwitch';
import SearchButton from '../uikits/SearchButton';
import { HouseIcon, RegionIcon, DropDownIcon } from '../icons';

const propTypes = {
  isAdmin: PropTypes.bool,
  classes: PropTypes.instanceOf(Object).isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  houses: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  handleSearch: PropTypes.func,
  handleChangeOption: PropTypes.func,
};

const defaultProps = {
  houses: [],
  locations: [],
  isAdmin: false,
  handleSearch: () => {},
  handleChangeOption: () => {},
};

const styles = (theme) => ({
  header: {
    paddingTop: '1.92rem',
    marginBottom: '0.64rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '1.2rem',
    },
  },
  container: {
    paddingLeft: '1.92rem',
    paddingRight: '1.92rem',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '1.2rem',
      paddingRight: '1.2rem',
    },
  },
  wrp: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  filterBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    order: 1,
  },
  filterBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 'auto',
    minHeight: 'auto',
    color: '#4A5568',
    padding: 0,
  },
  searchText: {
    fontSize: '1.125rem',
    fontWeight: 600,
  },
  filterText: {
    fontSize: '1rem',
  },
  searchIconOpen: {
    transform: 'rotate(180deg)',
  },
  filterIconOpen: {
    transform: 'rotate(180deg)',
  },
  mainBlock: {
    maxWidth: '25.2rem',
    marginRight: '1.5rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: '20.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      order: 1,
      marginRight: 0,
      marginBottom: '2rem',
    },
  },
  centerBlock: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      order: 1,
      flexDirection: 'column',
    },
  },
  tickerWrp: {
    display: 'flex',
    alignItems: 'center',
  },
  tickerSearch: {
    minWidth: '12.48rem',
    marginRight: '1rem',
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      minWidth: '11.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginRight: 0,
      marginTop: '1.2rem',
    },
  },
  checkboxLabel: {
    whiteSpace: 'nowrap',
  },
  textSearch: {
    width: '100%',
    marginBottom: 0,
    marginTop: '0.64rem',
  },
  searchBtn: {
    width: '100%',
    marginTop: '2rem',
  },
  searchBtnHidden: {
    marginTop: '1.36rem',
  },
  btn: {
    marginTop: '0.64rem',
  },
  checkboxWrp: {
    display: 'flex',
    marginRight: 0,
  },
  checkbox: {
    width: '1.92rem',
    height: '1.92rem',
  },
  right: {
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-end',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      order: 0,
      marginLeft: 0,
      marginBottom: '2.188rem',
    },
  },
  rightWrp: {
    order: 2,
  },
  rightBlock: {
    marginLeft: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginLeft: '0.5rem',
      marginTop: '1.36rem',
    },
  },
  login: {
    display: 'flex',
    alignItems: 'baseline',
  },
  username: {
    color: '#303E4E',
    fontSize: '1.12rem',
    fontWeight: 600,
  },
  logoutBtn: {
    minWidth: '5rem',
    minHeight: '3.12rem',
    color: '#4A5568',
    backgroundColor: '#F7FAFC',
    border: '0.08rem solid #EDF2F7',
    marginLeft: '0.8rem',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  autocompleteWrp: {
    display: 'flex',
  },
  ac: {
    flexGrow: 1,
    '& .text-field': {
      marginBottom: '0.64rem',
      maxWidth: '20rem',
      minWidth: '20rem',
      [theme.breakpoints.down('md')]: {
        maxWidth: '16rem',
        minWidth: '16rem',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  },
  clearIndicator: {
    display: 'none',
  },
  tag: {
    margin: 0,
    marginRight: '0.24rem',
  },
  input: {
    minWidth: 'auto !important',
  },
  acInfo: {
    display: 'flex',
    alignItems: 'center',
    color: '#4A5568',
    height: '3.2rem',
    marginRight: '1.28rem',
    minWidth: '5.6rem',
    '& svg': {
      color: '#748297',
      marginRight: '0.48rem',
    },
  },
  line: {
    display: 'flex',
    height: '0.16rem',
    backgroundColor: '#EDF2F7',
    margin: '1rem 1.92rem 0 1.92rem',
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 1.2rem 0 1.2rem',
    },
  },
  lineHide: {
    display: 'none',
  },
});

function Header(props) {
  const {
    classes,
    isAdmin,
    value,
    handleChangeOption,
    locations,
    houses,
    handleSearch,
  } = props;
  const [point, setPoint] = useState({
    ticker: true,
    summaries: true,
    tables: true,
    nonLatin: true,
  });

  const [showSearch, setShowSearch] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const username = useSelector((state) => state.app.user.username);

  const history = useHistory();

  const handleChange = (event) => {
    setPoint({ ...point, [event.target.name]: event.target.checked });
  };

  const handleSearchOpen = () => {
    setShowSearch((prevState) => !prevState);
  };

  const handleAdvancedOpen = () => {
    setShowAdvanced((prevState) => !prevState);
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push('/login');
  };

  const searchIconClassName = cn(classes.searchIcon, {
    [classes.searchIconOpen]: showSearch,
  });

  const filterIconClassName = cn(classes.filterIcon, {
    [classes.filterIconOpen]: showAdvanced,
  });

  const lineClassName = cn(classes.line, {
    [classes.lineHide]: !showSearch,
  });

  const searchBtnClassName = cn(classes.searchBtn, {
    [classes.searchBtnHidden]: !showAdvanced,
  });

  return (
    <>
      <div className={classes.header}>
        <Container className={classes.container}>
          <div className={classes.wrp}>
            <Hidden mdUp>
              <div className={classes.filterBlock}>
                <Button
                  className={classes.filterBtn}
                  onClick={handleSearchOpen}
                >
                  <Typography className={classes.searchText}>
                    Search
                  </Typography>
                  <DropDownIcon className={searchIconClassName} />
                </Button>
                {showSearch ? (
                  <Button
                    className={classes.filterBtn}
                    onClick={handleAdvancedOpen}
                  >
                    <Typography className={classes.filterText}>
                      Advanced
                    </Typography>
                    <DropDownIcon className={filterIconClassName} />
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </Hidden>
            <Hidden mdUp>
              {showSearch ? (
                <>
                  <div className={classes.mainBlock}>
                    <TextField
                      variant="outlined"
                      label="Search by ticker"
                      className={classes.tickerSearch}
                    />
                    <TextField
                      variant="outlined"
                      label="Search by headline"
                      className={classes.textSearch}
                    />
                    <TextField
                      variant="outlined"
                      label="Search by full text"
                      className={classes.textSearch}
                    />
                  </div>
                  <div className={classes.centerBlock}>
                    <div className={classes.autocompleteWrp}>
                      <span className={classes.acInfo}>
                        <RegionIcon />
                        <span>Region</span>
                      </span>
                      <Autocomplete
                        value={value.locations}
                        onChange={handleChangeOption('locations')}
                        className={classes.ac}
                        multiple
                        limitTags={1}
                        disabledItemsFocusable
                        id="autocomplete"
                        options={locations}
                        closeIcon={<></>}
                        disableCloseOnSelect
                        classes={{
                          clearIndicator: classes.clearIndicator,
                          inputRoot: classes.inputRoot,
                          tag: classes.tag,
                          input: classes.input,
                        }}
                        getOptionLabel={(option) =>
                          option.location_name
                        }
                        renderOption={(option, { selected }) => (
                          <>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.location_name}
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            className="text-field"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            variant="outlined"
                            label="Select regions"
                          />
                        )}
                      />
                    </div>
                    <div className={classes.autocompleteWrp}>
                      <span className={classes.acInfo}>
                        <HouseIcon />
                        <span>Houses</span>
                      </span>
                      <Autocomplete
                        value={value.houses}
                        onChange={handleChangeOption('houses')}
                        className={classes.ac}
                        multiple
                        limitTags={1}
                        disabledItemsFocusable
                        id="autocomplete"
                        options={houses}
                        closeIcon={<></>}
                        disableCloseOnSelect
                        classes={{
                          clearIndicator: classes.clearIndicator,
                          inputRoot: classes.inputRoot,
                          tag: classes.tag,
                          input: classes.input,
                        }}
                        getOptionLabel={(option) =>
                          option.housename_full
                        }
                        renderOption={(option, { selected }) => (
                          <>
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.housename_full}
                          </>
                        )}
                        renderInput={(params) => (
                          <TextField
                            className="text-field"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            variant="outlined"
                            label="Select houses"
                          />
                        )}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className={classes.rightWrp}>
                {showSearch && showAdvanced ? (
                  <div className={classes.rightBlock}>
                    <FormControlLabel
                      classes={{
                        label: classes.checkboxLabel,
                        root: classes.checkboxWrp,
                      }}
                      control={
                        <Checkbox
                          checked={point.summaries}
                          onChange={handleChange}
                          name="summaries"
                          className={classes.checkbox}
                        />
                      }
                      label="Only summaries"
                    />
                    <FormControlLabel
                      classes={{
                        label: classes.checkboxLabel,
                        root: classes.checkboxWrp,
                      }}
                      control={
                        <Checkbox
                          checked={point.nonLatin}
                          onChange={handleChange}
                          name="nonLatin"
                          className={classes.checkbox}
                        />
                      }
                      label="Exclude non-latin files"
                    />
                    <FormControlLabel
                      classes={{
                        label: classes.checkboxLabel,
                        root: classes.checkboxWrp,
                      }}
                      control={
                        <Checkbox
                          checked={point.tables}
                          onChange={handleChange}
                          name="tables"
                          className={classes.checkbox}
                        />
                      }
                      label="Exclude files with only tables"
                    />
                    <FormControlLabel
                      classes={{
                        label: classes.checkboxLabel,
                        root: classes.checkboxWrp,
                      }}
                      control={
                        <Checkbox
                          className={classes.checkbox}
                          checked={point.ticker}
                          onChange={handleChange}
                          name="ticker"
                        />
                      }
                      label="Main ticker only"
                    />
                  </div>
                ) : (
                  ''
                )}
                {showSearch && (
                  <SearchButton
                    className={searchBtnClassName}
                    handleSearch={handleSearch}
                  />
                )}
              </div>
            </Hidden>
            <Hidden smDown>
              <div className={classes.mainBlock}>
                <div className={classes.tickerWrp}>
                  <TextField
                    variant="outlined"
                    label="Search by ticker"
                    className={classes.tickerSearch}
                  />
                  <FormControlLabel
                    classes={{
                      label: classes.checkboxLabel,
                      root: classes.checkboxWrp,
                    }}
                    control={
                      <Checkbox
                        checked={point.ticker}
                        onChange={handleChange}
                        name="ticker"
                      />
                    }
                    label="Main ticker only"
                  />
                </div>
                <TextField
                  variant="outlined"
                  label="Search by headline"
                  className={classes.textSearch}
                />
                <TextField
                  variant="outlined"
                  label="Search by full text"
                  className={classes.textSearch}
                />
              </div>
              <div className={classes.centerBlock}>
                <div>
                  <div className={classes.autocompleteWrp}>
                    <span className={classes.acInfo}>
                      <RegionIcon />
                      <span>Region</span>
                    </span>
                    <Autocomplete
                      value={value.locations}
                      onChange={handleChangeOption('locations')}
                      className={classes.ac}
                      multiple
                      limitTags={1}
                      disabledItemsFocusable
                      id="autocomplete"
                      options={locations}
                      closeIcon={<></>}
                      disableCloseOnSelect
                      classes={{
                        clearIndicator: classes.clearIndicator,
                        inputRoot: classes.inputRoot,
                        tag: classes.tag,
                        input: classes.input,
                      }}
                      getOptionLabel={(option) =>
                        option.location_name
                      }
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.location_name}
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField
                          className="text-field"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          variant="outlined"
                          label="Select regions"
                        />
                      )}
                    />
                  </div>
                  <div className={classes.autocompleteWrp}>
                    <span className={classes.acInfo}>
                      <HouseIcon />
                      <span>Houses</span>
                    </span>
                    <Autocomplete
                      value={value.houses}
                      onChange={handleChangeOption('houses')}
                      className={classes.ac}
                      multiple
                      limitTags={1}
                      disabledItemsFocusable
                      id="autocomplete"
                      options={houses}
                      closeIcon={<></>}
                      disableCloseOnSelect
                      classes={{
                        clearIndicator: classes.clearIndicator,
                        inputRoot: classes.inputRoot,
                        tag: classes.tag,
                        input: classes.input,
                      }}
                      getOptionLabel={(option) =>
                        option.housename_full
                      }
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.housename_full}
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField
                          className="text-field"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          variant="outlined"
                          label="Select houses"
                        />
                      )}
                    />
                  </div>
                  <SearchButton
                    className={classes.btn}
                    handleSearch={handleSearch}
                  />
                </div>
                <div className={classes.rightBlock}>
                  <FormControlLabel
                    classes={{
                      label: classes.checkboxLabel,
                      root: classes.checkboxWrp,
                    }}
                    control={
                      <Checkbox
                        checked={point.summaries}
                        onChange={handleChange}
                        name="summaries"
                        className={classes.checkbox}
                      />
                    }
                    label="Only summaries"
                  />
                  <FormControlLabel
                    classes={{
                      label: classes.checkboxLabel,
                      root: classes.checkboxWrp,
                    }}
                    control={
                      <Checkbox
                        checked={point.tables}
                        onChange={handleChange}
                        name="tables"
                        className={classes.checkbox}
                      />
                    }
                    label="Exclude files with only tables"
                  />
                  <FormControlLabel
                    classes={{
                      label: classes.checkboxLabel,
                      root: classes.checkboxWrp,
                    }}
                    control={
                      <Checkbox
                        checked={point.nonLatin}
                        onChange={handleChange}
                        name="nonLatin"
                        className={classes.checkbox}
                      />
                    }
                    label="Exclude non-latin files"
                  />
                </div>
              </div>
            </Hidden>
            <div className={classes.right}>
              {isAdmin && (
                <ParserSwitch className={classes.switch} isPDF />
              )}
              <div className={classes.login}>
                <span className={classes.username}>{username}</span>
                <Button
                  color="primary"
                  className={classes.logoutBtn}
                  onClick={handleLogout}
                >
                  Log out
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Hidden mdUp>
        <span className={lineClassName} />
      </Hidden>
    </>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
export default withStyles(styles)(Header);
