import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CancelIcon = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M9.878 7.999l5.728-5.716A1.338 1.338 0 1013.714.391L8 6.12 2.286.392A1.338 1.338 0 10.394 2.283l5.728 5.715-5.728 5.716a1.338 1.338 0 101.892 1.89L8 9.875l5.715 5.729a1.338 1.338 0 101.891-1.89z" />
    </SvgIcon>
  );
};

export default CancelIcon;
