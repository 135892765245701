import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Container, withStyles } from '@material-ui/core';
import ParserSwitch from '../uikits/ParserSwitch';

const propTypes = {
  isAdmin: PropTypes.bool,
  classes: PropTypes.instanceOf(Object).isRequired,
  // getHouse: PropTypes.func.isRequired,
};

const defaultProps = {
  isAdmin: false,
};

const styles = (theme) => ({
  header: {
    paddingTop: '1.92rem',
    marginBottom: '0.64rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '1.2rem',
    },
  },
  container: {
    paddingLeft: '1.92rem',
    paddingRight: '1.92rem',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '1.2rem',
      paddingRight: '1.2rem',
    },
  },
  wrp: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  filterBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    order: 1,
  },
  filterBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 'auto',
    minHeight: 'auto',
    color: '#4A5568',
    padding: 0,
  },
  searchText: {
    fontSize: '1.125rem',
    fontWeight: 600,
  },
  searchIconOpen: {
    transform: 'rotate(180deg)',
  },
  filterIconOpen: {
    transform: 'rotate(180deg)',
  },
  mainBlock: {
    maxWidth: '25.2rem',
    marginRight: '1.5rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: '20.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      order: 1,
      marginRight: 0,
      marginBottom: '2rem',
    },
  },
  block: {
    display: 'flex',
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '48%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '49%',
    },
  },
  centerBlock: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      order: 1,
      flexDirection: 'column',
    },
  },
  tickerWrp: {
    display: 'flex',
    alignItems: 'center',
  },
  tickerSearch: {
    minWidth: '12.48rem',
    marginRight: '1rem',
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      minWidth: '11.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginRight: 0,
      marginTop: '1.2rem',
    },
  },
  checkboxLabel: {
    whiteSpace: 'nowrap',
  },
  textSearch: {
    width: '100%',
    marginBottom: 0,
    marginTop: '0.64rem',
  },
  searchBtn: {
    width: '100%',
    marginTop: '2rem',
  },
  searchBtnHidden: {
    marginTop: '1.36rem',
  },
  btn: {
    marginTop: '0.64rem',
  },
  checkboxWrp: {
    display: 'flex',
    marginRight: 0,
  },
  checkbox: {
    width: '1.92rem',
    height: '1.92rem',
  },
  right: {
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-end',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      order: 0,
      marginLeft: 0,
      marginBottom: '2.188rem',
    },
  },
  rightWrp: {
    order: 2,
  },
  rightBlock: {
    marginLeft: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginLeft: '0.5rem',
      marginTop: '1.36rem',
    },
  },
  login: {
    display: 'flex',
    alignItems: 'baseline',
  },
  username: {
    color: '#303E4E',
    fontSize: '1.12rem',
    fontWeight: 600,
  },
  logoutBtn: {
    minWidth: '5rem',
    minHeight: '3.12rem',
    color: '#4A5568',
    backgroundColor: '#F7FAFC',
    border: '0.08rem solid #EDF2F7',
    marginLeft: '0.8rem',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  autocompleteWrp: {
    display: 'flex',
  },
  ac: {
    flexGrow: 1,
    '& .text-field': {
      marginBottom: '0.64rem',
      maxWidth: '20rem',
      minWidth: '20rem',
      [theme.breakpoints.down('md')]: {
        maxWidth: '16rem',
        minWidth: '16rem',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  },
  clearIndicator: {
    display: 'none',
  },
  tag: {
    margin: 0,
    marginRight: '0.24rem',
  },
  input: {
    minWidth: 'auto !important',
  },
  acInfo: {
    display: 'flex',
    alignItems: 'center',
    color: '#4A5568',
    height: '3.2rem',
    marginRight: '1.28rem',
    minWidth: '5.6rem',
    '& svg': {
      color: '#748297',
      marginRight: '0.48rem',
    },
  },
  line: {
    display: 'flex',
    height: '0.16rem',
    backgroundColor: '#EDF2F7',
    margin: '1rem 1.92rem 0 1.92rem',
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 1.2rem 0 1.2rem',
    },
  },
  lineHide: {
    display: 'none',
  },
  select: {
    height: '3.2rem',
    color: '#4A5568',
    marginBottom: '0.8rem',
    maxWidth: '180px',
  },
  outlined: {
    borderRadius: 0,
    border: 'none',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  filterText: {
    fontSize: '1.125rem',
    fontWeight: 600,
    color: '#4A5568',
  },
  icon: {
    fontSize: '1.5rem',
    right: '0.5rem',
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
  paper: {
    overflowY: 'hidden',
    boxShadow: 'none',
    marginTop: '0.8rem',
  },
  info: {
    minWidth: '2.8rem',
    display: 'flex',
    alignItems: 'center',
    color: '#4A5568',
    height: '3.2rem',
    whiteSpace: 'nowrap',
    marginRight: '1.6rem',
    '& svg': {
      color: '#748297',
      marginRight: '0.48rem',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '5.36rem',
      marginRight: '0.8rem',
    },
  },
});

function Header(props) {
  const { classes, isAdmin } = props;

  const username = useSelector((state) => state.app.user.username);

  // const [filter, setFilter] = useState('any');

  const history = useHistory();

  const handleLogout = () => {
    localStorage.clear();
    history.push('/login');
  };

  // const getHouseFilter = (e) => {
  //   setFilter(e.target.value);
  //   getHouse(e.target.value);
  // };

  return (
    <>
      <div className={classes.header}>
        <Container className={classes.container}>
          <div className={classes.wrp}>
            {/* <div className={classes.block}></div> */}
            <div className={classes.right}>
              {isAdmin && <ParserSwitch className={classes.switch} />}
              <div className={classes.login}>
                <span className={classes.username}>{username}</span>
                <Button
                  color="primary"
                  className={classes.logoutBtn}
                  onClick={handleLogout}
                >
                  Log out
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;
export default withStyles(styles)(Header);
