import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  withStyles,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import {
  HouseIcon
} from '../icons';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import superagent from 'superagent';
import ParserSwitch from '../uikits/ParserSwitch';
import SearchButton from '../uikits/SearchButton';
// import { houses } from '../pages/PageParser'

const propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
};

const statuses = [
  'SUCCESS_ATTACH',
  'SUCCESS_DOWNLOAD',
  'FAIL',
  'NON-PDF',
  'IGNORED_DUPLICATE',
  'IGNORED_BY_RULE',
  'SENT_TO_MAILBOX',
];

const styles = (theme) => ({
  header: {
    paddingTop: '1.92rem',
    marginBottom: '0.64rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '1.2rem',
    },
  },
  container: {
    paddingLeft: '1.92rem',
    paddingRight: '1.92rem',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '1.2rem',
      paddingRight: '1.2rem',
    },
  },
  wrp: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  filterBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    order: 1,
  },
  filterBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 'auto',
    minHeight: 'auto',
    color: '#4A5568',
    padding: 0,
  },
  searchText: {
    fontSize: '1.125rem',
    fontWeight: 600,
  },
  searchIconOpen: {
    transform: 'rotate(180deg)',
  },
  filterIconOpen: {
    transform: 'rotate(180deg)',
  },
  mainBlock: {
    maxWidth: '25.2rem',
    marginRight: '1.5rem',
    [theme.breakpoints.down('md')]: {
      maxWidth: '20.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      order: 1,
      marginRight: 0,
      marginBottom: '2rem',
    },
  },
  block: {
    display: 'flex',
    width: '30%',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '48%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      width: '49%',
    },
  },
  centerBlock: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      order: 1,
      flexDirection: 'column',
    },
  },
  tickerWrp: {
    display: 'flex',
    alignItems: 'center',
  },
  tickerSearch: {
    minWidth: '12.48rem',
    marginRight: '1rem',
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      minWidth: '11.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginRight: 0,
      marginTop: '1.2rem',
    },
  },
  checkboxLabel: {
    whiteSpace: 'nowrap',
  },
  textSearch: {
    width: '100%',
    marginBottom: 0,
    marginTop: '0.64rem',
  },
  searchBtn: {
    width: '10%',
    marginLeft: '1.7rem'
  },
  searchBtnHidden: {
    marginTop: '1.36rem',
  },
  btn: {
    marginTop: '0.64rem',
  },
  checkboxWrp: {
    display: 'flex',
    marginRight: 0,
  },
  checkbox: {
    width: '1.92rem',
    height: '1.92rem',
  },
  contentWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
      marginTop: '1rem',
    },
  },
  rightWrp: {
    order: 2,
  },
  rightBlock: {
    marginLeft: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginLeft: '0.5rem',
      marginTop: '1.36rem',
    },
  },
  login: {
    display: 'flex',
    alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
  username: {
    color: '#303E4E',
    fontSize: '1.12rem',
    fontWeight: 600,
  },
  logoutBtn: {
    minWidth: '5rem',
    minHeight: '3.12rem',
    color: '#4A5568',
    backgroundColor: '#F7FAFC',
    border: '0.08rem solid #EDF2F7',
    marginLeft: '0.8rem',
    whiteSpace: 'nowrap',
  },
  autocompleteWrp: {
    display: 'flex',
  },
  flushBtn: {
    minWidth: '5rem',
    minHeight: '2.12rem',
    height: '40px',
    color: '#4A5568',
    backgroundColor: '#F7FAFC',
    border: '0.08rem solid #EDF2F7',
    marginLeft: '0.8rem',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
  ac: {
    flexGrow: 1,
    '& .text-field': {
      marginBottom: '0.64rem',
      maxWidth: '20rem',
      minWidth: '20rem',
      [theme.breakpoints.down('md')]: {
        maxWidth: '16rem',
        minWidth: '16rem',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  },
  clearIndicator: {
    display: 'none',
  },
  tag: {
    margin: 0,
    marginRight: '0.24rem',
  },
  input: {
    minWidth: 'auto !important',
  },
  acInfo: {
    display: 'flex',
    alignItems: 'center',
    color: '#4A5568',
    height: '3.2rem',
    marginRight: '1.28rem',
    minWidth: '5.6rem',
    '& svg': {
      color: '#748297',
      marginRight: '0.48rem',
    },
  },
  line: {
    display: 'flex',
    height: '0.16rem',
    backgroundColor: '#EDF2F7',
    margin: '1rem 1.92rem 0 1.92rem',
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 1.2rem 0 1.2rem',
    },
  },
  lineHide: {
    display: 'none',
  },
  select: {
    height: '3.2rem',
    color: '#4A5568',
    marginBottom: 0,
    maxWidth: '180px',
  },
  outlined: {
    borderRadius: 0,
    border: 'none',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  filterText: {
    fontSize: '1.125rem',
    fontWeight: 600,
    color: '#4A5568',
  },
  icon: {
    fontSize: '1.5rem',
    right: '0.5rem',
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
    paper: {
    overflowY: 'hidden',
    boxShadow: 'none',
    marginTop: '0.8rem',
  },
  list: {
    width: 'auto',
    maxHeight: '25.6rem',
    overflowY: 'auto',
    padding: '0.4rem',
  },
  info: {
    minWidth: '2.8rem',
    display: 'flex',
    alignItems: 'center',
    color: '#4A5568',
    height: '3.2rem',
    whiteSpace: 'nowrap',
    marginRight: '1.6rem',
    '& svg': {
      color: '#748297',
      marginRight: '0.48rem',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '5.36rem',
      marginRight: '0.8rem',
    },
  },
});

function Header(props) {
  const {
    classes,
    isAdmin,
    filterHouse,
    setFilterHouse,
    broker,
    setBroker,
    handleFilter,
    houses
  } = props;

  const username = useSelector((state) => state.app.user.username);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const history = useHistory();

  const handleLogout = () => {
    localStorage.clear();
    history.push('/login');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    superagent
      .post('/history/purge')
      .then(() => {
        setOpen(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <div className={classes.header}>
        <Container className={classes.container}>
          <div className={classes.contentWrap}>
            <div className={classes.left}>
              <span className={classes.info}>
                <span>Status</span>
              </span>
              <Select
                id="history"
                className={classes.select}
                fullWidth
                variant="outlined"
                value={filterHouse}
                classes={{
                  iconOutlined: classes.icon,
                  outlined: classes.outlined,
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  classes: {
                    paper: classes.paper,
                    list: classes.list,
                  },
                }}
                onChange={(e) => setFilterHouse(e.target.value)}
              >
                <MenuItem
                  value="any"
                  className={classes.item}
                  classes={{ selected: classes.selected }}
                >
                  Any
                </MenuItem>
                {statuses.map((s) => {
                  return (
                    <MenuItem
                      key={s}
                      value={s}
                      className={classes.item}
                      classes={{ selected: classes.selected }}
                    >
                      {s}
                    </MenuItem>
                  );
                })}
              </Select>

              <span className={classes.info} style={{ marginLeft: '1.6rem'}}>
                <HouseIcon />
                <span>House</span>
              </span>
              <Select
                id="houses"
                className={classes.select}
                fullWidth
                variant="outlined"
                value={broker}
                classes={{
                  iconOutlined: classes.icon,
                  outlined: classes.outlined,
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  classes: {
                    paper: classes.paper,
                    list: classes.list,
                  },
                }}
                onChange={(e) => setBroker(e.target.value)}
              >
                <MenuItem
                  value={-1}
                  className={classes.item}
                  classes={{ selected: classes.selected }}
                >
                  No rules
                </MenuItem>
                {houses.sort((a,b) => {
  if (a.name < b.name)
    return -1;
  if (a.name > b.name)
    return 1;
  return 0;
}).map((h) => (
                  <MenuItem
                    key={h.value}
                    value={h.name}
                    className={classes.item}
                    classes={{ selected: classes.selected }}
                  >
                    {h.name}
                  </MenuItem>
                ))}
              </Select>

              <SearchButton
                handleSearch={handleFilter}
                className={classes.searchBtn}
              />

              <Button
                color="primary"
                className={classes.flushBtn}
                onClick={() => setOpen(true)}
              >
                Flush history
              </Button>
            </div>
            <div className={classes.right}>
              {isAdmin && <ParserSwitch className={classes.switch} />}
              <div className={classes.login}>
                <span className={classes.username}>{username}</span>
                <Button
                  color="primary"
                  className={classes.logoutBtn}
                  onClick={handleLogout}
                >
                  Log out
                </Button>
              </div>
            </div>
          </div>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              Are you sure you want to delete history?
            </DialogTitle>
            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                No
              </Button>
              <Button
                onClick={handleDelete}
                color="primary"
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </div>
    </>
  );
}

Header.propTypes = propTypes;
export default withStyles(styles)(Header);
