import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, withStyles } from '@material-ui/core';
import { SearchIcon } from '../icons';

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.instanceOf(Object).isRequired,
  handleSearch: PropTypes.func,
};

const defaultProps = {
  className: '',
  handleSearch: () => {},
};

const styles = () => ({
  btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 400,
    lineHeight: 0,
  },
  searchIcon: {
    fontSize: '1rem',
    marginRight: '0.4rem',
  },
});

const SearchButton = (props) => {
  const { classes, className, handleSearch } = props;

  return (
    <Button
      variant="contained"
      color="primary"
      className={cn(classes.btn, className)}
      onClick={handleSearch}
    >
      <SearchIcon className={classes.searchIcon} />
      Search
    </Button>
  );
};

SearchButton.propTypes = propTypes;
SearchButton.defaultProps = defaultProps;

export default withStyles(styles)(SearchButton);
