import React from 'react';
import { SvgIcon } from '@material-ui/core';

const InfoIcon = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M12 2a10 10 0 1010 10A10.011 10.011 0 0012 2zm0 18a8 8 0 118-8 8.01 8.01 0 01-8 8zm0-8.5a1 1 0 00-1 1v3a1 1 0 002 0v-3a1 1 0 00-1-1zm0-4a1.25 1.25 0 101.25 1.25A1.25 1.25 0 0012 7.5z" />
    </SvgIcon>
  );
};

export default InfoIcon;
