import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CheckboxIcon = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <rect
        data-name="Checkbox"
        width="16"
        height="16"
        rx="4"
        fill="#303e4e"
      />
      <path
        d="M11.817 5.174a.557.557 0 00-.808 0L6.768 9.553 4.986 7.71a.569.569 0 00-.823.015.613.613 0 00.014.848l2.186 2.254a.557.557 0 00.808 0l4.645-4.79a.6.6 0 000-.863z"
        fill="#fff"
      />
    </SvgIcon>
  );
};

export default CheckboxIcon;
